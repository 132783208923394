<section class="homequote country-search" id="quoteMenu">
  <div class="container-md py-md-0 p-0 quotecontain">
    <div class="row justify-content-center">
      <div class="col-lg-12 col-12 p-0">
        <div class="card quotecard m-0">
          <div class="card-body p-0">
            <div class="d-flex justify-content-between mobpad">
              <h3 class="fw-bold mb-3">{{ getaquotewording }}</h3>
            </div>
            <nav>
              <div class="nav nav-tabs quotehome" id="nav-tab" role="tablist">
                <button
                  class="nav-link text-dark ps-md-5 pe-md-5 ps-2 pe-2"
                  [ngClass]="{ active: isSingleTrip }"
                  id="nav-single-trip-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-single-trip"
                  type="button"
                  role="tab"
                  aria-controls="nav-single-trip"
                  aria-selected="isSingleTrip"
                  (click)="
                    onSingleTripClick(); onBlurChangesSegment('tripType')
                  "
                >
                  Single Trip
                </button>
                <button
                  class="nav-link text-dark ps-md-5 pe-md-5 ps-2 pe-2"
                  [ngClass]="{ active: !isSingleTrip }"
                  id="nav-multi-trip-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-multi-trip"
                  type="button"
                  role="tab"
                  aria-controls="nav-multi-trip"
                  aria-selected="!isSingleTrip"
                  (click)="onAMTSelect(); onBlurChangesSegment('tripType')"
                >
                  Annual Multi-Trip
                </button>
                <p class="quotereq mb-0">* required fields</p>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade py-4"
                [ngClass]="{ 'show active': isSingleTrip }"
                id="nav-single-trip"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
                tabindex="0"
              >
                <p class="smalltext lightcbg col-lg-6 col-12 rounded">
                  <img src="assets/images/raa/info.png" class="" />
                  Provides coverage for a single domestic or international round
                  trip. You can even plan to visit multiple destinations or
                  multiple countries, and that's still considered as single
                  trip. The policy will be in force until you return from the
                  trip.
                </p>
                <form action="" [formGroup]="singleTripForm">
                  <div class="row g-2 align-items-start">
                    <div
                      class="col-12 col-md-12 col-lg-6 form-floating ng-select-floating-container"
                    >
                      <ng-select
                        name="selectedCountries"
                        [items]="filteredCountries"
                        bindLabel="countryName"
                        [multiple]="true"
                        [(ngModel)]="selectedCountries"
                        [clearOnBackspace]="true"
                        [clearSearchOnAdd]="true"
                        (change)="onModelChange($event)"
                        dropdownPosition="bottom"
                        maxSelectedItems="300"
                        [searchFn]="searchFn"
                        (search)="countrySearchFn($event)"
                        [isOpen]="searchTerms?.length >= 2 ? true : false"
                        [openOnEnter]="false"
                        [ngModelOptions]="{ standalone: true }"
                        [hideSelected]="true"
                        (focus)="ngSelectIsFocused = true"
                        (blur)="
                          handlecountrySearcBlur($event);
                          onBlurChangesSegment('selectedCountries')
                        "
                      >
                        <ng-template ng-optgroup-tmp let-item="item">
                          <span style="vertical-align: middle">{{
                            item.name
                          }}</span>
                        </ng-template>
                      </ng-select>
                      <label
                        class="ng-select-floating-label"
                        [class.active]="
                          selectedCountries?.length || ngSelectIsFocused
                        "
                        for="selectedCountries"
                        >Travel Destinations* (Please choose from the list as
                        you type your destinations.)</label
                      >
                      <div class="d-block d-lg-none mobile-error">
                        <small class="text-danger">
                          <div
                            class="error-message"
                            *ngIf="singleTripForm.get('travelDestination')?.errors?.['cautionCountry']"
                          >
                            {{singleTripForm.get('travelDestination')?.errors?.['cautionCountry']}}
                          </div>
                        </small>
                        <small class="text-danger">
                          <div
                            class="error-message"
                            *ngIf="singleTripForm.get('travelDestination')?.errors?.['cannotTravelCountry']"
                          >
                            <a
                              href="https://www.smartraveller.gov.au/destinations"
                              target="_blank"
                              >One or more of your destinations has a Travel
                              Alert, please click here to view the Alert(s).</a
                            >
                            {{singleTripForm.get('travelDestination')?.errors?.['cannotTravelCountry']}}
                          </div>
                        </small>
                        <small class="text-danger">
                          <div
                            class="error-message"
                            *ngIf="singleTripForm.get('travelDestination')?.errors?.['cannotTravelDomesticInternational']"
                          >
                            {{singleTripForm.get('travelDestination')?.errors?.['cannotTravelDomesticInternational']}}
                          </div>
                        </small>
                        <ng-container
                          *ngIf="
                            singleTripForm.get('travelDestination')?.invalid &&
                            (singleTripForm.get('travelDestination')?.dirty ||
                              singleTripForm.get('travelDestination')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <p
                              class="mb-0"
                              *ngIf="singleTripForm.get('travelDestination')?.errors?.['required']"
                            >
                              {{ travelDestinationRequiredMessage }}
                            </p>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-12 d-black d-md-none">
                      <div
                        class="p-2 lightcbg d-flex align-items-start rounded col-12 col-lg-6"
                      >
                        <small class="smalltext"
                          >You can add multiple countries. Are you going on an
                          Australian ocean cruise for 2 nights or more? 1 night
                          Australian ocean cruises are automatically covered –
                          select Australia as your destination.</small
                        >
                        <div
                          (click)="toggleTooltip()"
                          class="tooltip-container"
                        >
                          <img
                            src="assets/images/raa/tooltipcolor.png"
                            class="tooltipc"
                          />
                          <ng-container *ngIf="isTooltipVisibleTravDest">
                            <ng-container
                              *ngTemplateOutlet="travelDestinationTooltip"
                            ></ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-12 col-lg-3 form-floating smallmargin"
                    >
                      <p-floatLabel>
                        <p-calendar
                          [(ngModel)]="departureDate"
                          name="departureDate"
                          inputId="departureDate"
                          dateFormat="dd/mm/yy"
                          [minDate]="minDepartDate"
                          [maxDate]="maxDepartDate"
                          (onSelect)="setReturnDateAndRange()"
                          (onBlur)="
                            inputDepartValidation($event);
                            onBlurChangesSegment('departureDate')
                          "
                          [keepInvalid]="true"
                          [showIcon]="false"
                          [iconDisplay]="'input'"
                          inputId="icondisplay"
                          aria-label="Depart * (dd/mm/yyyy)"
                          formControlName="depart"
                          inputStyleClass="full-width-calendar-input form-control"
                          showOtherMonths="false"
                        >
                        </p-calendar>
                        <img
                          class="calendar-con"
                          src="assets/images/raa/travel-events.svg"
                        />
                        <label class="greyPlaceholder" for="departureDate"
                          >Depart* (dd/mm/yyyy)</label
                        >
                      </p-floatLabel>
                      <div class="d-block d-lg-none mobile-error">
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.get('depart')?.errors?.['invalidInputDepart']"
                          >
                            {{singleTripForm.get('depart')?.errors?.['invalidInputDepart']}}
                          </p>
                        </small>
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.get('depart')?.errors?.['departDateMoreThan18Months']"
                          >
                            {{singleTripForm.get('depart')?.errors?.['departDateMoreThan18Months']}}
                          </p>
                        </small>
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.get('depart')?.errors?.['departDateBeforeToday']"
                          >
                            {{singleTripForm.get('depart')?.errors?.['departDateBeforeToday']}}
                          </p>
                        </small>
                        <ng-container
                          *ngIf="
                            singleTripForm.get('depart')?.invalid &&
                            (singleTripForm.get('depart')?.dirty ||
                              singleTripForm.get('depart')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <p
                              class="mb-0"
                              *ngIf="singleTripForm.get('depart')?.errors?.['required']"
                            >
                              {{ departDateRequiredMessage }}
                            </p>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-12 col-lg-3 form-floating smallmargin return-calendar"
                    >
                      <p-floatLabel>
                        <p-calendar
                          [(ngModel)]="returnDate"
                          name="returnDate"
                          dateFormat="dd/mm/yy"
                          [minDate]="minReturnDate"
                          [maxDate]="maxReturnDate"
                          (onBlur)="
                            inputReturnValidation($event, 'singleTrip');
                            onBlurChangesSegment('returnDate')
                          "
                          [keepInvalid]="true"
                          [showIcon]="false"
                          [iconDisplay]="'input'"
                          inputId="icondisplay"
                          aria-label="Return * (dd/mm/yyyy)"
                          formControlName="return"
                          inputStyleClass="full-width-calendar-input form-control"
                          showOtherMonths="false"
                        >
                        </p-calendar>
                        <img
                          class="calendar-con"
                          src="assets/images/raa/travel-events.svg"
                        />
                        <label class="greyPlaceholder" for="returnDate"
                          >Return* (dd/mm/yyyy)</label
                        >
                      </p-floatLabel>
                      <div class="d-block d-lg-none mobile-error">
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.get('return')?.errors?.['returnDateMoreThan365Days']"
                          >
                            {{singleTripForm.get('return')?.errors?.['returnDateMoreThan365Days']}}
                          </p>
                        </small>
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.get('return')?.errors?.['returnDateBeforeDepart']"
                          >
                            {{singleTripForm.get('return')?.errors?.['returnDateBeforeDepart']}}
                          </p>
                        </small>
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.get('return')?.errors?.['invalidInputReturn']"
                          >
                            {{singleTripForm.get('return')?.errors?.['invalidInputReturn']}}
                          </p>
                        </small>
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.errors?.['returnBeforeDepartDate']"
                          >
                            {{singleTripForm.errors?.['returnBeforeDepartDate']}}
                          </p>
                        </small>
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.errors?.['returnGreaterThanOneYear']"
                          >
                            {{singleTripForm.errors?.['returnGreaterThanOneYear']}}
                          </p>
                        </small>
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.errors?.['datesEqual']"
                          >
                            {{singleTripForm.errors?.['datesEqual']}}
                          </p>
                        </small>
                        <ng-container
                          *ngIf="
                            singleTripForm.get('return')?.invalid &&
                            (singleTripForm.get('return')?.dirty ||
                              singleTripForm.get('return')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <p
                              class="mb-0"
                              *ngIf="singleTripForm.get('return')?.errors?.['required']"
                            >
                              {{ returnRequiredMessage }}
                            </p>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <!-- Error messages -->
                  <div class="row g-2 py-2 align-items-start">
                    <div class="col-12 col-md-6 d-none d-lg-block">
                      <small class="text-danger">
                        <div
                          class="error-message"
                          *ngIf="singleTripForm.get('travelDestination')?.errors?.['cautionCountry']"
                        >
                          {{singleTripForm.get('travelDestination')?.errors?.['cautionCountry']}}
                        </div>
                      </small>
                      <small class="text-danger">
                        <div
                          class="error-message"
                          *ngIf="singleTripForm.get('travelDestination')?.errors?.['cannotTravelCountry']"
                        >
                          <a
                            href="https://www.smartraveller.gov.au/destinations"
                            target="_blank"
                            >One or more of your destinations has a Travel
                            Alert, please click here to view the Alert(s).</a
                          >
                          {{singleTripForm.get('travelDestination')?.errors?.['cannotTravelCountry']}}
                        </div>
                      </small>
                      <small class="text-danger">
                        <div
                          class="error-message"
                          *ngIf="singleTripForm.get('travelDestination')?.errors?.['cannotTravelDomesticInternational']"
                        >
                          {{singleTripForm.get('travelDestination')?.errors?.['cannotTravelDomesticInternational']}}
                        </div>
                      </small>
                      <ng-container
                        *ngIf="
                          singleTripForm.get('travelDestination')?.invalid &&
                          (singleTripForm.get('travelDestination')?.dirty ||
                            singleTripForm.get('travelDestination')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.get('travelDestination')?.errors?.['required']"
                          >
                            {{ travelDestinationRequiredMessage }}
                          </p>
                        </small>
                      </ng-container>
                    </div>
                    <div class="col-12 col-md-3 d-none d-lg-block">
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="singleTripForm.get('depart')?.errors?.['invalidInputDepart']"
                        >
                          {{singleTripForm.get('depart')?.errors?.['invalidInputDepart']}}
                        </p>
                      </small>
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="singleTripForm.get('depart')?.errors?.['departDateMoreThan18Months']"
                        >
                          {{singleTripForm.get('depart')?.errors?.['departDateMoreThan18Months']}}
                        </p>
                      </small>
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="singleTripForm.get('depart')?.errors?.['departDateBeforeToday']"
                        >
                          {{singleTripForm.get('depart')?.errors?.['departDateBeforeToday']}}
                        </p>
                      </small>
                      <ng-container
                        *ngIf="
                          singleTripForm.get('depart')?.invalid &&
                          (singleTripForm.get('depart')?.dirty ||
                            singleTripForm.get('depart')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.get('depart')?.errors?.['required']"
                          >
                            {{ departDateRequiredMessage }}
                          </p>
                        </small>
                      </ng-container>
                    </div>
                    <div class="col-12 col-md-3 d-none d-lg-block">
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="singleTripForm.get('return')?.errors?.['returnDateMoreThan365Days']"
                        >
                          {{singleTripForm.get('return')?.errors?.['returnDateMoreThan365Days']}}
                        </p>
                      </small>
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="singleTripForm.get('return')?.errors?.['returnDateBeforeDepart']"
                        >
                          {{singleTripForm.get('return')?.errors?.['returnDateBeforeDepart']}}
                        </p>
                      </small>
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="singleTripForm.get('return')?.errors?.['invalidInputReturn']"
                        >
                          {{singleTripForm.get('return')?.errors?.['invalidInputReturn']}}
                        </p>
                      </small>
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="singleTripForm.errors?.['returnBeforeDepartDate']"
                        >
                          {{singleTripForm.errors?.['returnBeforeDepartDate']}}
                        </p>
                      </small>
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="singleTripForm.errors?.['returnGreaterThanOneYear']"
                        >
                          {{singleTripForm.errors?.['returnGreaterThanOneYear']}}
                        </p>
                      </small>
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="singleTripForm.errors?.['datesEqual']"
                        >
                          {{singleTripForm.errors?.['datesEqual']}}
                        </p>
                      </small>
                      <ng-container
                        *ngIf="
                          singleTripForm.get('return')?.invalid &&
                          (singleTripForm.get('return')?.dirty ||
                            singleTripForm.get('return')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.get('return')?.errors?.['required']"
                          >
                            {{ returnRequiredMessage }}
                          </p>
                        </small>
                      </ng-container>
                    </div>
                  </div>
                  <div
                    class="row g-2 align-items-start mt-0 mb-3 d-none d-md-block"
                  >
                    <div
                      class="p-2 lightcbg my-2 d-flex align-items-start rounded col-12 col-lg-6"
                    >
                      <small class="smalltext"
                        >You can add multiple countries. Are you going on an
                        Australian ocean cruise for 2 nights or more? 1 night
                        Australian ocean cruises are automatically covered –
                        select Australia as your destination.</small
                      >
                      <img
                        src="assets/images/raa/info.png"
                        class="tooltipc"
                        [pTooltip]="travelDestinationTooltip"
                        tooltipPosition="right"
                        tooltipStyleClass="travel-destination-tooltip"
                      />
                    </div>
                  </div>
                  <div class="row g-2 align-items-start">
                    <div class="col-6 col-sm-6 col-md-4 col-xl form-floating">
                      <input
                        type="tel"
                        [(ngModel)]="age1"
                        id="adult-1-age"
                        class="form-control"
                        placeholder=""
                        aria-label="Adult 1 Age *"
                        maxLength="3"
                        formControlName="age1"
                        pKeyFilter="int"
                        (blur)="onBlurChangesSegment('adult1Age')"
                      />
                      <label class="custom-label-placeholder" for="adult-1-age"
                        >Adult 1 Age*</label
                      >
                      <div class="d-block d-xl-none p-2 mobile-error">
                        <ng-container
                          *ngIf="
                            singleTripForm.get('age1')?.invalid &&
                            (singleTripForm.get('age1')?.dirty ||
                              singleTripForm.get('age1')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <p
                              class="mb-0"
                              *ngIf="singleTripForm.get('age1')?.errors?.['required']"
                            >
                              {{errorMessages['first_age']?.['required']}}
                            </p>
                            <p
                              class="mb-0"
                              *ngIf="singleTripForm.get('age1')?.errors?.['min'] && showErrorAge1"
                            >
                              {{errorMessages['first_age']?.['min']}}
                            </p>
                            <p
                              class="mb-0"
                              *ngIf="singleTripForm.get('age1')?.errors?.['max'] && showErrorAge1"
                            >
                              {{errorMessages['first_age']?.['max']}}
                            </p>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-xl form-floating">
                      <input
                        type="tel"
                        [(ngModel)]="age2"
                        id="adult-2-age"
                        class="form-control"
                        placeholder=""
                        aria-label="Adult 2 Age"
                        maxLength="3"
                        formControlName="age2"
                        pKeyFilter="int"
                        (blur)="onBlurChangesSegment('adult2Age')"
                      />
                      <label class="custom-label-placeholder" for="adult-2-age"
                        >Adult 2 Age</label
                      >
                      <div class="d-block d-xl-none p-2 mobile-error">
                        <ng-container
                          *ngIf="
                            showErrorAge2 &&
                            singleTripForm.get('age2')?.invalid &&
                            (singleTripForm.get('age2')?.dirty ||
                              singleTripForm.get('age2')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <p
                              class="mb-0"
                              *ngIf="singleTripForm.get('age2')?.errors?.['min']"
                            >
                              {{errorMessages['second_age']?.['min']}}
                            </p>
                            <p
                              class="mb-0"
                              *ngIf="singleTripForm.get('age2')?.errors?.['max']"
                            >
                              {{errorMessages['second_age']?.['max']}}
                            </p>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-xl form-floating">
                      <input
                        type="tel"
                        [(ngModel)]="dependentsCount"
                        id="dependents"
                        class="form-control"
                        placeholder=""
                        aria-label="Dependents Count"
                        maxLength="2"
                        formControlName="dependentsCount"
                        pKeyFilter="int"
                        (blur)="onBlurChangesSegment('dependentsCount')"
                      />
                      <label class="custom-label-placeholder" for="dependents"
                        >No. of Dependents</label
                      >
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-xl">
                      <div class="select-container">
                        <select
                          [(ngModel)]="state"
                          name=""
                          id="state"
                          class="form-select select"
                          formControlName="state"
                          (blur)="onBlurChangesSegment('addressState')"
                        >
                          <option
                            *ngFor="let state of stateList"
                            value="{{ state.id }}"
                          >
                            {{ state.code }}
                          </option>
                        </select>
                        <label
                          class="label"
                          for="state"
                          [class.floating]="state"
                          >State*</label
                        >
                      </div>
                      <div class="d-block d-xl-none p-2 mobile-error">
                        <ng-container
                          *ngIf="
                            singleTripForm.get('state')?.invalid &&
                            (singleTripForm.get('state')?.dirty ||
                              singleTripForm.get('state')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <p
                              class="mb-0"
                              *ngIf="singleTripForm.get('state')?.errors?.['required']"
                            >
                              {{ stateRequiredMessage }}
                            </p>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-12 d-black d-md-none">
                      <div
                        class="p-2 lightcbg d-flex align-items-center rounded"
                      >
                        <small class="smalltext"
                          >Dependents such as children, grandchildren,
                          step-children and foster children, aged 25 or under at
                          no extra cost - Specific criteria applies.</small
                        >
                        <div
                          (click)="toggleTooltip()"
                          class="tooltip-container"
                        >
                          <img
                            src="assets/images/raa/info.png"
                            class="tooltipc"
                          />
                          <ng-container *ngIf="isTooltipVisible">
                            <ng-container
                              *ngTemplateOutlet="dependentTooltip"
                            ></ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-6 col-sm-4 col-md-4 col-xl-2 memberselect"
                      *ngIf="activatedClub !== 'W2C'"
                    >
                      <div class="select-wrap">
                        <label class="membership-select-label"
                          >Are You an {{ activatedClub }} Member?*</label
                        >
                        <select
                          [(ngModel)]="isClubMember"
                          class="form-select"
                          name="state"
                          style="width: 100%"
                          formControlName="isClubMember"
                          (blur)="onBlurChangesSegment('membership')"
                        >
                          <option value="true" selected>Yes</option>
                          <option value="false">No</option>
                        </select>
                        <p class="mb-0" class="subtext d-none d-xl-block">
                          Members save 10% on travel insurance
                        </p>
                      </div>
                      <p class="mb-0" class="subtext d-block d-xl-none">
                        Members save 10% on travel insurance
                      </p>
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-xl form-floating">
                      <input
                        [(ngModel)]="promoCode"
                        type="text"
                        id="promo"
                        class="form-control"
                        placeholder=""
                        (blur)="
                          validatePromoCode($event);
                          onBlurChangesSegment('promoCode')
                        "
                        formControlName="promoCode"
                      />
                      <label class="custom-label-placeholder" for="id"
                        >Enter promo code</label
                      >
                      <div class="d-block d-xl-none p-2 mobile-error">
                        <ng-container>
                          <small
                            [ngClass]="
                              promoCodeMessageValidity
                                ? 'text-success'
                                : 'text-danger'
                            "
                          >
                            <p class="mb-0 promocode">
                              {{ promoCodeMessage }}
                            </p>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-xl">
                      <button
                        type="submit"
                        class="btn btn-lg qbtn btn-info w-100 text-center py-2"
                        (click)="onSingleTripSubmit()"
                        [disabled]="isButtonDisabled"
                      >
                        Get a Quote
                      </button>
                    </div>
                  </div>

                  <!-- Error Messages -->
                  <div class="row g-2 align-items-start">
                    <div
                      class="col-6 col-sm-6 col-md-4 col-xl form-floating d-none d-xl-block"
                    >
                      <ng-container
                        *ngIf="
                          singleTripForm.get('age1')?.invalid &&
                          (singleTripForm.get('age1')?.dirty ||
                            singleTripForm.get('age1')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.get('age1')?.errors?.['required']"
                          >
                            {{errorMessages['first_age']?.['required']}}
                          </p>
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.get('age1')?.errors?.['min'] && showErrorAge1"
                          >
                            {{errorMessages['first_age']?.['min']}}
                          </p>
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.get('age1')?.errors?.['max'] && showErrorAge1"
                          >
                            {{errorMessages['first_age']?.['max']}}
                          </p>
                        </small>
                      </ng-container>
                    </div>
                    <div
                      class="col-6 col-sm-6 col-md-4 col-xl form-floating d-none d-xl-block"
                    >
                      <ng-container
                        *ngIf="
                          showErrorAge2 &&
                          singleTripForm.get('age2')?.invalid &&
                          (singleTripForm.get('age2')?.dirty ||
                            singleTripForm.get('age2')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.get('age2')?.errors?.['min']"
                          >
                            {{errorMessages['second_age']?.['min']}}
                          </p>
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.get('age2')?.errors?.['max']"
                          >
                            {{errorMessages['second_age']?.['max']}}
                          </p>
                        </small>
                      </ng-container>
                    </div>
                    <div class="col-12 d-black d-md-none"></div>
                    <div
                      class="col-6 col-sm-4 col-md-4 col-xl form-floating d-none d-xl-block"
                    >
                      <ng-container
                        *ngIf="
                          singleTripForm.get('state')?.invalid &&
                          (singleTripForm.get('state')?.dirty ||
                            singleTripForm.get('state')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="singleTripForm.get('state')?.errors?.['required']"
                          >
                            {{ stateRequiredMessage }}
                          </p>
                        </small>
                      </ng-container>
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-xl"></div>
                    <div
                      class="col-6 col-sm-4 col-md-2"
                      *ngIf="activatedClub !== 'W2C'"
                    ></div>
                    <div
                      class="col-6 col-sm-4 col-md-4 col-xl form-floating d-none d-xl-block"
                    >
                      <ng-container>
                        <small
                          [ngClass]="
                            promoCodeMessageValidity
                              ? 'text-success'
                              : 'text-danger'
                          "
                        >
                          <p class="mb-0 promocode">
                            {{ promoCodeMessage }}
                          </p>
                        </small>
                      </ng-container>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-xl"></div>
                  </div>
                  <div class="row col-offset-x-5 d-none d-md-block">
                    <div class="col-12 col-sm-12 col-xl-6">
                      <div
                        class="p-2 lightcbg my-2 d-flex align-items-center rounded"
                      >
                        <small class="smalltext"
                          >The travel policy covers up to two adult travellers.
                          Please enter the current age of the adult travellers.
                          Dependents such as children, grandchildren,
                          step-children and foster children aged 25 or under at
                          no extra cost. - Specific criteria apply.
                        </small>
                        <img
                          src="assets/images/raa/info.png"
                          class="tooltipc"
                          [pTooltip]="dependentTooltip"
                          tooltipPosition="right"
                          tooltipStyleClass="dependent-tooltip"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Disclaimer -->
                  <div class="row g-2 align-items-start">
                    <div class="col-12 col-sm-12 col-md-12 col-xl">
                      <div
                        class="p-2 lightcbg my-2 align-items-center rounded disclaimer"
                      >
                        <small><strong>Disclaimer</strong></small>
                        <p></p>
                        <p class="smalltext">
                          Note that travel insurance benefits are subject to the
                          terms and conditions (including the limits, sub-limits
                          and exclusions) of the insurance policy. Please read
                          the
                          <a
                            rel="noopener noreferrer"
                            href="{{ pdsLink }}"
                            target="_blank"
                          >
                            'RAA Travel Insurance- Combined Financial Services
                            Guide and Product Disclosure Statement'</a
                          >
                          carefully to understand what each policy covers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Review of Policy Terms -->
                  <div class="row g-2 align-items-start">
                    <div class="col-12 col-sm-12 col-md-12 col-xl">
                      <div
                        class="p-2 lightcbg my-2 align-items-center rounded disclaimer"
                      >
                        <small><strong>Review of Policy Terms</strong></small>
                        <p></p>
                        <p class="smalltext">
                          ASIC has called on general insurers to review their
                          insurance policies to ensure that they are transparent
                          and do not contain unfair terms. We have made
                          enhancements to the cover that we provide for existing
                          medical conditions, please click
                          <a
                            href="/review-policy"
                            target="_blank"
                            style="color: #001657 !important"
                            class="underline text-light"
                            ><strong>here</strong></a
                          >
                          for more information.
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div
                class="tab-pane fade py-4"
                [ngClass]="{ 'show active': !isSingleTrip }"
                id="nav-multi-trip"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
                tabindex="0"
              >
                <form action="" [formGroup]="multiTripForm">
                  <p class="smalltext lightcbg rounded col-lg-6 col-12">
                    <img src="assets/images/raa/tooltipcolor.png" class="" />
                    <small class="smalltext">
                      Provides cover for an unlimited number of trips within a
                      12 month period, 250 kms from home. Max trip duration 30,
                      45, 60 days. Please provide date you depart for your first
                      trip.
                    </small>
                  </p>
                  <div class="row g-2 align-items-start">
                    <div
                      class="col-12 col-md-12 col-lg-6 form-floating ng-select-floating-container"
                    >
                      <ng-select
                        name="selectedCountries"
                        [items]="filteredCountries"
                        bindLabel="countryName"
                        [multiple]="true"
                        [(ngModel)]="selectedCountries"
                        [clearOnBackspace]="true"
                        [clearSearchOnAdd]="true"
                        (change)="onModelChange($event)"
                        dropdownPosition="bottom"
                        maxSelectedItems="300"
                        [searchFn]="searchFn"
                        (search)="countrySearchFn($event)"
                        [isOpen]="searchTerms?.length >= 2 ? true : false"
                        [openOnEnter]="false"
                        [ngModelOptions]="{ standalone: true }"
                        [hideSelected]="true"
                        (focus)="ngSelectIsFocused = true"
                        (blur)="
                          handlecountrySearcBlur($event);
                          onBlurChangesSegment('selectedCountries')
                        "
                      >
                        <ng-template ng-optgroup-tmp let-item="item">
                          <span style="vertical-align: middle">{{
                            item.name
                          }}</span>
                        </ng-template>
                      </ng-select>
                      <label
                        class="ng-select-floating-label"
                        [class.active]="
                          selectedCountries?.length || ngSelectIsFocused
                        "
                        for="selectedCountries"
                        >Travel Destination* (Please choose from the list as you
                        type your destinations.)</label
                      >
                      <div class="d-block d-lg-none mobile-error">
                        <small class="text-danger">
                          <div
                            class="error-message"
                            *ngIf="multiTripForm.errors?.['cautionCountry']"
                          >
                            {{multiTripForm.errors?.['cautionCountry']}}
                          </div>
                        </small>
                        <small class="text-danger">
                          <div
                            class="error-message"
                            *ngIf="multiTripForm.errors?.['cannotTravelCountry']"
                          >
                            <a
                              href="https://www.smartraveller.gov.au/destinations"
                              target="_blank"
                              >One or more of your destinations has a Travel
                              Alert, please click here to view the Alert(s).</a
                            >
                            {{multiTripForm.errors?.['cannotTravelCountry']}}
                          </div>
                        </small>
                        <small class="text-danger">
                          <div
                            class="error-message"
                            *ngIf="multiTripForm.errors?.['cannotSelectDomesticAMTW2C']"
                          >
                            {{multiTripForm.errors?.['cannotSelectDomesticAMTW2C']}}
                          </div>
                        </small>
                        <ng-container
                          *ngIf="
                            multiTripForm.get('travelDestination')?.invalid &&
                            (multiTripForm.get('travelDestination')?.dirty ||
                              multiTripForm.get('travelDestination')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <p
                              class="mb-0"
                              *ngIf="multiTripForm.get('travelDestination')?.errors?.['required']"
                            >
                              {{ travelDestinationRequiredMessage }}
                            </p>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-12 d-black d-md-none">
                      <div
                        class="p-2 lightcbg d-flex align-items-start rounded col-12 col-lg-6"
                      >
                        <small class="smalltext"
                          >You can add multiple countries. Are you going on an
                          Australian ocean cruise for 2 nights or more? 1 night
                          Australian ocean cruises are automatically covered –
                          select Australia as your destination.</small
                        >
                        <div
                          (click)="toggleTooltip()"
                          class="tooltip-container"
                        >
                          <img
                            src="assets/images/raa/tooltipcolor.png"
                            class="tooltipc"
                          />
                          <ng-container *ngIf="isTooltipVisibleTravDest">
                            <ng-container
                              *ngTemplateOutlet="travelDestinationTooltip"
                            ></ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-12 col-lg-3 form-floating smallmargin"
                    >
                      <p-floatLabel>
                        <p-calendar
                          [(ngModel)]="departureDate"
                          [minDate]="minDepartDate"
                          [maxDate]="maxDepartDate"
                          (onSelect)="setReturnDateAndRange()"
                          (onBlur)="
                            inputDepartValidation($event);
                            onBlurChangesSegment('departureDate')
                          "
                          [keepInvalid]="true"
                          [showIcon]="false"
                          [iconDisplay]="'input'"
                          inputId="icondisplay"
                          name="departureDateMulti"
                          dateFormat="dd/mm/yy"
                          aria-label="Depart * (dd/mm/yyyy)"
                          formControlName="depart"
                          inputStyleClass="full-width-calendar-input form-control"
                          showOtherMonths="false"
                        >
                        </p-calendar>
                        <img
                          class="calendar-con"
                          src="assets/images/raa/travel-events.svg"
                        />
                        <label class="greyPlaceholder" for="departureDateMulti"
                          >Start Date* (dd/mm/yyyy)</label
                        >
                      </p-floatLabel>
                      <div class="d-block d-lg-none mobile-error">
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.get('depart')?.errors?.['invalidInputDepart']"
                          >
                            {{multiTripForm.get('depart')?.errors?.['invalidInputDepart']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.get('depart')?.errors?.['departDateMoreThan18Months']"
                          >
                            {{multiTripForm.get('depart')?.errors?.['departDateMoreThan18Months']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.get('depart')?.errors?.['departDateBeforeToday']"
                          >
                            {{multiTripForm.get('depart')?.errors?.['departDateBeforeToday']}}
                          </span>
                        </small>
                        <ng-container
                          *ngIf="
                            multiTripForm.get('depart')?.invalid &&
                            (multiTripForm.get('depart')?.dirty ||
                              multiTripForm.get('depart')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="multiTripForm.get('depart')?.errors?.['required']"
                            >
                              {{ departDateRequiredMessageMulti }}
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-12 col-lg-3 form-floating smallmargin return-calendar"
                    >
                      <p-floatLabel>
                        <p-calendar
                          [(ngModel)]="returnDateMulti"
                          (onBlur)="onBlurChangesSegment('returnDate')"
                          [showIcon]="false"
                          [iconDisplay]="'input'"
                          inputId="icondisplay"
                          name="returnDateMulti"
                          dateFormat="dd/mm/yy"
                          aria-label="Return * (dd/mm/yyyy)"
                          formControlName="return"
                          inputStyleClass="full-width-calendar-input form-control"
                          class="disabled-calendar"
                          [tabindex]="-1"
                          showOtherMonths="false"
                        >
                        </p-calendar>
                        <img
                          class="calendar-con"
                          src="assets/images/raa/travel-events.svg"
                        />
                        <label class="greyPlaceholder" for="returnDateMulti"
                          >End Date* (dd/mm/yyyy)</label
                        >
                      </p-floatLabel>
                      <div class="d-block d-lg-none mobile-error">
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.get('return')?.errors?.['invalidInputReturnMulti']"
                          >
                            {{multiTripForm.get('return')?.errors?.['invalidInputReturnMulti']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.get('return')?.errors?.['returnDateBeforeDepart']"
                          >
                            {{multiTripForm.get('return')?.errors?.['returnDateBeforeDepart']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.get('return')?.errors?.['returnDateMoreThan60Days']"
                          >
                            {{multiTripForm.get('return')?.errors?.['returnDateMoreThan60Days']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.errors?.['returnDateMoreThan60Days']"
                          >
                            {{multiTripForm.errors?.['returnDateMoreThan60Days']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.errors?.['returnBeforeDepartDateMulti']"
                          >
                            {{multiTripForm.errors?.['returnBeforeDepartDateMulti']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.errors?.['datesEqualMulti']"
                          >
                            {{multiTripForm.errors?.['datesEqualMulti']}}
                          </span>
                        </small>
                        <ng-container
                          *ngIf="
                            multiTripForm.get('return')?.invalid &&
                            (multiTripForm.get('return')?.dirty ||
                              multiTripForm.get('return')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="multiTripForm.get('return')?.errors?.['required']  && showRequiredReturnDateMultiError"
                            >
                              {{ returnRequiredMessage }}
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <!-- Error messages -->
                  <div class="row g-2 py-2 align-items-start">
                    <div class="col-12 col-lg-6 d-none d-lg-block">
                      <small class="text-danger">
                        <div
                          class="error-message"
                          *ngIf="multiTripForm.errors?.['cautionCountry']"
                        >
                          {{multiTripForm.errors?.['cautionCountry']}}
                        </div>
                      </small>
                      <small class="text-danger">
                        <div
                          class="error-message"
                          *ngIf="multiTripForm.errors?.['cannotTravelCountry']"
                        >
                          <a
                            href="https://www.smartraveller.gov.au/destinations"
                            target="_blank"
                            >One or more of your destinations has a Travel
                            Alert, please click here to view the Alert(s).</a
                          >
                          {{multiTripForm.errors?.['cannotTravelCountry']}}
                        </div>
                      </small>
                      <small class="text-danger">
                        <div
                          class="error-message"
                          *ngIf="multiTripForm.errors?.['cannotSelectDomesticAMTW2C']"
                        >
                          {{multiTripForm.errors?.['cannotSelectDomesticAMTW2C']}}
                        </div>
                      </small>
                      <ng-container
                        *ngIf="
                          multiTripForm.get('travelDestination')?.invalid &&
                          (multiTripForm.get('travelDestination')?.dirty ||
                            multiTripForm.get('travelDestination')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="multiTripForm.get('travelDestination')?.errors?.['required']"
                          >
                            {{ travelDestinationRequiredMessage }}
                          </p>
                        </small>
                      </ng-container>
                    </div>
                    <div class="col-12 col-md-3 d-none d-lg-block">
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="multiTripForm.get('depart')?.errors?.['invalidInputDepart']"
                        >
                          {{multiTripForm.get('depart')?.errors?.['invalidInputDepart']}}
                        </p>
                      </small>
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="multiTripForm.get('depart')?.errors?.['departDateMoreThan18Months']"
                        >
                          {{multiTripForm.get('depart')?.errors?.['departDateMoreThan18Months']}}
                        </p>
                      </small>
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="multiTripForm.get('depart')?.errors?.['departDateBeforeToday']"
                        >
                          {{multiTripForm.get('depart')?.errors?.['departDateBeforeToday']}}
                        </p>
                      </small>
                      <ng-container
                        *ngIf="
                          multiTripForm.get('depart')?.invalid &&
                          (multiTripForm.get('depart')?.dirty ||
                            multiTripForm.get('depart')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="multiTripForm.get('depart')?.errors?.['required']"
                          >
                            {{ departDateRequiredMessageMulti }}
                          </p>
                        </small>
                      </ng-container>
                    </div>
                    <div class="col-12 col-md-3 d-none d-lg-block">
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="multiTripForm.get('return')?.errors?.['invalidInputReturnMulti']"
                        >
                          {{multiTripForm.get('return')?.errors?.['invalidInputReturnMulti']}}
                        </p>
                      </small>
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="multiTripForm.get('return')?.errors?.['returnDateBeforeDepart']"
                        >
                          {{multiTripForm.get('return')?.errors?.['returnDateBeforeDepart']}}
                        </p>
                      </small>
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="multiTripForm.get('return')?.errors?.['returnDateMoreThan60Days']"
                        >
                          {{multiTripForm.get('return')?.errors?.['returnDateMoreThan60Days']}}
                        </p>
                      </small>
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="multiTripForm.errors?.['returnDateMoreThan60Days']"
                        >
                          {{multiTripForm.errors?.['returnDateMoreThan60Days']}}
                        </p>
                      </small>
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="multiTripForm.errors?.['returnBeforeDepartDateMulti']"
                        >
                          {{multiTripForm.errors?.['returnBeforeDepartDateMulti']}}
                        </p>
                      </small>
                      <small class="text-danger">
                        <p
                          class="mb-0"
                          *ngIf="multiTripForm.errors?.['datesEqualMulti']"
                        >
                          {{multiTripForm.errors?.['datesEqualMulti']}}
                        </p>
                      </small>
                      <ng-container
                        *ngIf="
                          multiTripForm.get('return')?.invalid &&
                          (multiTripForm.get('return')?.dirty ||
                            multiTripForm.get('return')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="multiTripForm.get('return')?.errors?.['required'] && showRequiredReturnDateMultiError"
                          >
                            {{ returnRequiredMessage }}
                          </p>
                        </small>
                      </ng-container>
                    </div>
                  </div>

                  <div
                    class="row g-2 align-items-center mt-0 mb-3 d-none d-md-block"
                  >
                    <div
                      class="col-12 col-lg-6 p-2 lightcbg my-2 d-flex align-items-center rounded"
                    >
                      <small class="smalltext"
                        >You can add multiple countries. Are you going on an
                        Australian ocean cruise for 2 nights or more? 1 night
                        Australian ocean cruises are automatically covered –
                        select Australia as your destination.</small
                      >
                      <img
                        src="assets/images/raa/tooltipcolor.png"
                        class="tooltipc"
                        [pTooltip]="travelDestinationTooltip"
                        tooltipPosition="right"
                        tooltipStyleClass="travel-destination-tooltip"
                      />
                    </div>
                  </div>

                  <div class="row g-2 align-items-start">
                    <div class="col-6 col-sm-6 col-md-4 col-xl form-floating">
                      <input
                        type="tel"
                        [(ngModel)]="age1"
                        id="adult-1-age"
                        class="form-control"
                        placeholder=""
                        aria-label="Adult 1 Age *"
                        maxLength="3"
                        formControlName="age1"
                        pKeyFilter="int"
                        (blur)="onBlurChangesSegment('adult1Age')"
                      />
                      <label class="custom-label-placeholder" for="adult-1-age"
                        >Adult 1 Age*</label
                      >
                      <div class="d-block d-xl-none p-2 mobile-error">
                        <ng-container
                          *ngIf="
                            multiTripForm.get('age1')?.invalid &&
                            (multiTripForm.get('age1')?.dirty ||
                              multiTripForm.get('age1')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <p
                              class="mb-0"
                              *ngIf="multiTripForm.get('age1')?.errors?.['required']"
                            >
                              {{errorMessages['first_age']?.['required']}}
                            </p>
                            <p
                              class="mb-0"
                              *ngIf="multiTripForm.get('age1')?.errors?.['min'] && showErrorAge1Multi"
                            >
                              {{errorMessages['first_age_multi']?.['min']}}
                            </p>
                            <p
                              class="mb-0"
                              *ngIf="multiTripForm.get('age1')?.errors?.['max'] && showErrorAge1Multi"
                            >
                              {{errorMessages['first_age_multi']?.['max']}}
                            </p>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-xl form-floating">
                      <input
                        type="tel"
                        [(ngModel)]="age2"
                        id="adult-2-age"
                        class="form-control"
                        placeholder=""
                        aria-label="Adult 2 Age"
                        maxLength="3"
                        formControlName="age2"
                        pKeyFilter="int"
                        (blur)="onBlurChangesSegment('adult2Age')"
                      />
                      <label class="custom-label-placeholder" for="adult-1-age"
                        >Adult 2 Age</label
                      >
                      <div class="d-block d-xl-none p-2 mobile-error">
                        <ng-container
                          *ngIf="
                            multiTripForm.get('age2')?.invalid &&
                            (multiTripForm.get('age2')?.dirty ||
                              multiTripForm.get('age2')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <p
                              class="mb-0"
                              *ngIf="multiTripForm.get('age2')?.errors?.['min'] && showErrorAge2Multi"
                            >
                              {{errorMessages['second_age_multi']?.['min']}}
                            </p>
                            <p
                              class="mb-0"
                              *ngIf="multiTripForm.get('age2')?.errors?.['max'] && showErrorAge2Multi"
                            >
                              {{errorMessages['second_age_multi']?.['max']}}
                            </p>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-xl form-floating">
                      <input
                        type="tel"
                        [(ngModel)]="dependentsCount"
                        class="form-control"
                        placeholder=""
                        aria-label="Dependents Count"
                        maxLength="2"
                        formControlName="dependentsCount"
                        pKeyFilter="int"
                        (blur)="onBlurChangesSegment('dependentsCount')"
                      />
                      <label class="custom-label-placeholder" for="dependents"
                        >No. of Dependents</label
                      >
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-xl">
                      <div class="select-container">
                        <select
                          [(ngModel)]="state"
                          name=""
                          id="state"
                          class="form-select select"
                          formControlName="state"
                          (blur)="onBlurChangesSegment('addressState')"
                        >
                          <option
                            *ngFor="let state of stateList"
                            value="{{ state.id }}"
                          >
                            {{ state.code }}
                          </option>
                        </select>
                        <label
                          class="label"
                          for="state"
                          [class.floating]="state"
                          >State*</label
                        >
                      </div>
                      <div class="d-block d-xl-none p-2 mobile-error">
                        <ng-container
                          *ngIf="
                            multiTripForm.get('state')?.invalid &&
                            (multiTripForm.get('state')?.dirty ||
                              multiTripForm.get('state')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="multiTripForm.get('state')?.errors?.['required']"
                            >
                              {{ stateRequiredMessage }}
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-12 d-black d-md-none">
                      <div
                        class="p-2 lightcbg d-flex align-items-center rounded"
                      >
                        <small class="smalltext"
                          >Dependents such as children, grandchildren,
                          step-children and foster children, aged 25 or under at
                          no extra cost - Specific criteria applies.</small
                        >
                        <div
                          (click)="toggleTooltip()"
                          class="tooltip-container"
                        >
                          <img
                            src="assets/images/raa/info.png"
                            class="tooltipc"
                          />
                          <ng-container *ngIf="isTooltipVisible">
                            <ng-container
                              *ngTemplateOutlet="dependentTooltip"
                            ></ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-6 col-sm-4 col-md-4 col-xl-2 memberselect"
                      *ngIf="activatedClub !== 'W2C'"
                    >
                      <div class="select-wrap">
                        <label>Are You an {{ activatedClub }} Member?*</label>
                        <select
                          [(ngModel)]="isClubMember"
                          class="form-select"
                          name="state"
                          style="width: 100%"
                          formControlName="isClubMember"
                          (blur)="onBlurChangesSegment('membership')"
                        >
                          <option value="true" selected>Yes</option>
                          <option value="false">No</option>
                        </select>
                        <p class="mb-0" class="subtext d-none d-xl-block">
                          Members save 10% on travel insurance
                        </p>
                      </div>
                      <p class="mb-0" class="subtext d-block d-xl-none">
                        Members save 10% on travel insurance
                      </p>
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-xl form-floating">
                      <input
                        [(ngModel)]="promoCode"
                        type="text"
                        class="form-control"
                        placeholder=""
                        (blur)="
                          validatePromoCode($event);
                          onBlurChangesSegment('promoCode')
                        "
                        formControlName="promoCode"
                      />
                      <label
                        class="custom-label-placeholder"
                        for="floatingPromocode"
                        >Enter Promo Code</label
                      >
                      <div class="d-block d-xl-none p-2 mobile-error">
                        <ng-container>
                          <small
                            [ngClass]="
                              promoCodeMessageValidity
                                ? 'text-success'
                                : 'text-danger'
                            "
                          >
                            <p class="mb-0">
                              {{ promoCodeMessage }}
                            </p>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-xl">
                      <button
                        type="submit"
                        class="btn btn-lg qbtn btn-info w-100 text-center py-2"
                        (click)="onMultiTripSubmit()"
                        [disabled]="isButtonDisabled"
                      >
                        Get a Quote
                      </button>
                    </div>
                  </div>

                  <!-- Error Messages -->
                  <div class="row g-2 align-items-start">
                    <div
                      class="col-6 col-sm-6 col-md-4 col-xl form-floating d-none d-xl-block"
                    >
                      <ng-container
                        *ngIf="
                          multiTripForm.get('age1')?.invalid &&
                          (multiTripForm.get('age1')?.dirty ||
                            multiTripForm.get('age1')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="multiTripForm.get('age1')?.errors?.['required']"
                          >
                            {{errorMessages['first_age']?.['required']}}
                          </p>
                          <p
                            class="mb-0"
                            *ngIf="multiTripForm.get('age1')?.errors?.['min'] && showErrorAge1Multi"
                          >
                            {{errorMessages['first_age_multi']?.['min']}}
                          </p>
                          <p
                            class="mb-0"
                            *ngIf="multiTripForm.get('age1')?.errors?.['max'] && showErrorAge1Multi"
                          >
                            {{errorMessages['first_age_multi']?.['max']}}
                          </p>
                        </small>
                      </ng-container>
                    </div>
                    <div
                      class="col-6 col-sm-6 col-md-4 col-xl form-floating d-none d-xl-block"
                    >
                      <ng-container
                        *ngIf="
                          showErrorAge2Multi &&
                          multiTripForm.get('age2')?.invalid &&
                          (multiTripForm.get('age2')?.dirty ||
                            multiTripForm.get('age2')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="multiTripForm.get('age2')?.errors?.['min']"
                          >
                            {{errorMessages['second_age_multi']?.['min']}}
                          </p>
                          <p
                            class="mb-0"
                            *ngIf="multiTripForm.get('age2')?.errors?.['max']"
                          >
                            {{errorMessages['second_age_multi']?.['max']}}
                          </p>
                        </small>
                      </ng-container>
                    </div>
                    <div
                      class="col-6 col-sm-4 col-md-4 col-xl form-floating d-none d-xl-block"
                    ></div>
                    <div class="col-6 col-sm-4 col-md-4 col-xl">
                      <ng-container
                        *ngIf="
                          multiTripForm.get('state')?.invalid &&
                          (multiTripForm.get('state')?.dirty ||
                            multiTripForm.get('state')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <p
                            class="mb-0"
                            *ngIf="multiTripForm.get('state')?.errors?.['required']"
                          >
                            {{ stateRequiredMessage }}
                          </p>
                        </small>
                      </ng-container>
                    </div>
                    <div
                      class="col-6 col-sm-6 col-md-2"
                      *ngIf="activatedClub !== 'W2C'"
                    ></div>
                    <div
                      class="col-6 col-sm-4 col-md-4 col-xl form-floating d-none d-xl-block"
                    >
                      <ng-container>
                        <small
                          [ngClass]="
                            promoCodeMessageValidity
                              ? 'text-success'
                              : 'text-danger'
                          "
                        >
                          <p class="mb-0 promocode">
                            {{ promoCodeMessage }}
                          </p>
                        </small>
                      </ng-container>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-xl"></div>
                  </div>

                  <div class="row col-offset-x-5 d-none d-md-block">
                    <div class="col-12 col-sm-12 col-xl-6">
                      <div
                        class="p-2 lightcbg my-2 d-flex align-items-center rounded"
                      >
                        <small class="smalltext"
                          >The travel policy covers up to two adult travellers.
                          Please enter the current age of the adult travellers.
                          Dependents such as children, grandchildren,
                          step-children and foster children aged 25 or under at
                          no extra cost. - Specific criteria apply.
                        </small>
                        <ng-container
                          *ngIf="
                            !isTooltipVisibleDep;
                            else dependentToolTipOnHover
                          "
                        >
                          <img
                            src="assets/images/raa/tooltipcolor.png"
                            class="tooltipc"
                            [pTooltip]="dependentTooltip"
                            tooltipPosition="right"
                            tooltipStyleClass="dependent-tooltip"
                          />
                        </ng-container>
                        <ng-template #dependentToolTipOnHover>
                          <div
                            (click)="toggleTooltip()"
                            class="tooltip-container"
                          >
                            <img
                              src="assets/images/raa/tooltipcolor.png"
                              class="tooltipc"
                            />
                            <ng-container *ngIf="isTooltipVisibleDep">
                              <ng-container
                                *ngTemplateOutlet="dependentTooltip"
                              ></ng-container>
                            </ng-container>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <!-- Disclaimer -->
                  <div class="row g-2 align-items-start">
                    <div class="col-12 col-sm-12 col-md-12 col-xl">
                      <div
                        class="p-2 lightcbg my-2 align-items-center rounded disclaimer"
                      >
                        <small><strong>Disclaimer</strong></small>
                        <p></p>
                        <p class="smalltext">
                          Note that travel insurance benefits are subject to the
                          terms and conditions (including the limits, sub-limits
                          and exclusions) of the insurance policy. Please read
                          the
                          <a
                            rel="noopener noreferrer"
                            href="{{ pdsLink }}"
                            target="_blank"
                          >
                            'RAA Travel Insurance- Combined Financial Services
                            Guide and Product Disclosure Statement'</a
                          >
                          carefully to understand what each policy covers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Review of Policy Terms -->
                  <div class="row g-2 align-items-start">
                    <div class="col-12 col-sm-12 col-md-12 col-xl">
                      <div
                        class="p-2 lightcbg my-2 align-items-center rounded disclaimer"
                      >
                        <small><strong>Review of Policy Terms</strong></small>
                        <p></p>
                        <p class="smalltext">
                          ASIC has called on general insurers to review their
                          insurance policies to ensure that they are transparent
                          and do not contain unfair terms. We have made
                          enhancements to the cover that we provide for existing
                          medical conditions, please click
                          <a
                            href="/review-policy"
                            target="_blank"
                            style="color: #001657 !important"
                            class="underline text-light"
                            ><strong>here</strong></a
                          >
                          for more information.
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #travelDestinationTooltip>
    <div
      [ngClass]="
        isTooltipVisibleTravDest ? { 'tooltip-content': true, show: true } : ''
      "
    >
      If you are going on an Australian ocean cruise which is 2 nights or more,
      you may still require a policy which includes medical cover whilst you are
      on board the ship. You will therefore need to select Australian Cruise as
      your country of destination. For more information on what types of cruises
      this cover applies to and to assist you in selecting the cover that is
      appropriate for your cruise, please select the Cruise page link at the top
      of this page.
    </div>
  </ng-template>

  <ng-template #dependentTooltip>
    <div
      [ngClass]="
        isTooltipVisible || isTooltipVisibleDep
          ? { 'tooltip-content': true, show: true }
          : ''
      "
    >
      Dependents 25 or under go free as long as they travel with you the whole
      time, are financially dependent on their parents, not working full-time,
      and do not require a medical assessment.
    </div>
  </ng-template>
</section>
