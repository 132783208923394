<div class="theme-steps">
  <racv-app-header></racv-app-header>

  <section id="sticky-quote-bar" class="bg-info shadow sticky-top">
    <form action="" [formGroup]="updateQuoteForm">
      <div class="container-xl blue-header">
        <div class="row py-3 g-1 offset-md-0 offset-xl-0 offset-sm-0">
          <!-- Trip Type -->
          <div class="col triptype">
            <div class="text-white">
              <span class="d-block"><small>Trip Type</small></span>
              <p-overlayPanel #trip styleClass="overlay-custom-trip-type">
                <div class="mb-2">
                  <label class="d-block font-20 mb-2"
                    ><strong>Trip Type</strong></label
                  >
                  <p-radioButton
                    name="TripType"
                    class="btn-check"
                    value="Single"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="selectedTripValue"
                    inputId="tripType_yes"
                    (click)="onTripTypeClick($event, 'Single')"
                  ></p-radioButton>

                  <label
                    for="tripType_yes"
                    class="btn btn-outline-info px-3 me-1"
                    [ngClass]="{
                      'selected-label': selectedTripLabel === 'Single'
                    }"
                    >Single Trip
                  </label>

                  <p-radioButton
                    name="TripType"
                    class="btn-check"
                    value="Multiple"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="selectedTripValue"
                    inputId="tripType_no"
                    (onClick)="onTripTypeClick($event, 'Multiple')"
                  >
                  </p-radioButton>

                  <label
                    for="tripType_no"
                    class="btn btn-outline-info px-3"
                    [ngClass]="{
                      'selected-label': selectedTripLabel === 'Multiple'
                    }"
                    >Annual Multi-Trip</label
                  >

                  <div class="d-flex align-items-top py-3">
                    <span class="pe-2"
                      ><i class="fa-solid fa-circle-info"></i
                    ></span>

                    <small
                      ><ng-container
                        *ngIf="
                          selectedMobileTripType === 'Multiple' ||
                            selectedTripLabel === 'Multiple';
                          else singleTripRemarks
                        "
                      >
                        Provides cover for an unlimited number of trips within a
                        12 month period, 250 kms from home. Max trip duration
                        30, 45, 60 days. Please provide date you depart for your
                        first trip.
                      </ng-container>
                      <ng-template #singleTripRemarks>
                        Provides cover for a single domestic or international
                        round trip.
                      </ng-template></small
                    >
                  </div>
                </div>
              </p-overlayPanel>
              <button
                type="button"
                (click)="trip.toggle($event)"
                class="text-white border-0 bg-transparent px-0 py-2 pt-0"
              >
                <b data-bs-original-title
                  >{{ getTripTypeLabel(quoteEnquiry?.tripType) }}
                </b>
                <span
                  ><i class="fa-solid fa-angle-down" data-bs-original-title></i
                ></span>
              </button>
            </div>
          </div>
          <!-- Travel Destination -->
          <div class="col destination">
            <div class="text-white">
              <span class="d-block"><small>Travel Destination</small></span>
              <p-overlayPanel #travelDestination>
                <div style="width: 380px">
                  <div>
                    <label class="d-block font-20 mb-2"
                      ><strong>Travel Destination</strong></label
                    >
                    <ng-select
                      name="selectedCountries"
                      [items]="filteredCountries"
                      bindLabel="countryName"
                      [multiple]="true"
                      [(ngModel)]="selectedCountries"
                      [clearOnBackspace]="true"
                      [clearSearchOnAdd]="true"
                      (change)="onModelChange($event)"
                      dropdownPosition="bottom"
                      maxSelectedItems="300"
                      [searchFn]="searchFn"
                      (search)="countrySearchFn($event)"
                      [isOpen]="searchTerms?.length >= 2 ? true : false"
                      [openOnEnter]="false"
                      [ngModelOptions]="{ standalone: true }"
                      [hideSelected]="true"
                      (focus)="ngSelectIsFocused = true"
                      (blur)="handlecountrySearcBlur($event)"
                      formControlName="travelDestination"
                      #ms
                    >
                      <ng-template ng-optgroup-tmp let-item="item">
                        <span style="vertical-align: middle">{{
                          item.name
                        }}</span>
                      </ng-template>
                    </ng-select>
                  </div>
                  <div>
                    <ng-container
                      *ngIf="
                        updateQuoteForm.get('travelDestination')?.invalid &&
                        (updateQuoteForm.get('travelDestination')?.dirty ||
                          updateQuoteForm.get('travelDestination')?.touched)
                      "
                    >
                      <small class="text-danger">
                        <div
                          class="error-message"
                          *ngIf="updateQuoteForm.get('travelDestination')?.errors?.['cautionCountry']"
                        >
                          {{updateQuoteForm.get('travelDestination')?.errors?.['cautionCountry']}}
                        </div>
                      </small>
                      <small class="text-danger">
                        <div
                          class="error-message"
                          *ngIf="updateQuoteForm.get('travelDestination')?.errors?.['cannotTravelCountry']"
                        >
                          <a
                            href="https://www.smartraveller.gov.au/destinations"
                            target="_blank"
                            >One or more of your destinations has a Travel
                            Alert, please click here to view the Alert(s).</a
                          >
                          {{updateQuoteForm.get('travelDestination')?.errors?.['cannotTravelCountry']}}
                        </div>
                      </small>
                      <small class="text-danger">
                        <span
                          *ngIf="updateQuoteForm.get('travelDestination')?.errors?.['required']"
                        >
                          Please select the Travel Destination
                        </span>
                      </small>
                      <small class="text-danger">
                        <div
                          class="error-message"
                          *ngIf="updateQuoteForm.get('travelDestination')?.errors?.['cannotTravelDomesticInternational']"
                        >
                          {{updateQuoteForm.get('travelDestination')?.errors?.['cannotTravelDomesticInternational']}}
                        </div>
                      </small>
                      <small class="text-danger">
                        <div
                          class="error-message"
                          *ngIf="updateQuoteForm.get('travelDestination')?.errors?.['cannotSelectDomesticAMTW2C']"
                        >
                          {{updateQuoteForm.get('travelDestination')?.errors?.['cannotSelectDomesticAMTW2C']}}
                        </div>
                      </small>
                    </ng-container>
                  </div>
                </div>
              </p-overlayPanel>
              <button
                #travelDestinationBtn
                type="button"
                (click)="travelDestination.toggle($event)"
                class="text-white border-0 bg-transparent px-0 py-2 pt-0"
              >
                <b data-bs-original-title>{{ getDestination() }}</b>
                <span class="ms-1"><i class="fa-solid fa-angle-down"></i></span>
              </button>
            </div>
          </div>
          <!-- Depart & Return Date -->
          <div class="col">
            <div class="text-white">
              <span class="d-block"><small>Depart & Return Date</small></span>
              <p-overlayPanel #departReturnDate>
                <div *ngIf="selectedTripLabel === 'Single'">
                  <p-calendar
                    formControlName="rangeDates"
                    (onSelect)="rangeDateSelect($event)"
                    [inline]="true"
                    selectionMode="range"
                    [readonlyInput]="true"
                    [minDate]="currentDate"
                    [maxDate]="maxDepartDate"
                    dateFormat="dd M ''y"
                    showOtherMonths="false"
                  ></p-calendar>
                </div>
                <div *ngIf="selectedTripLabel === 'Multiple'">
                  <p-calendar
                    formControlName="rangeDates"
                    (onSelect)="rangeDateSelect($event)"
                    [inline]="true"
                    [readonlyInput]="true"
                    [minDate]="currentDate"
                    [maxDate]="maxDepartDate"
                    dateFormat="dd M ''y"
                    showOtherMonths="false"
                  ></p-calendar>
                </div>
                <div class="drp-buttons">
                  <div class="drp-selected">
                    {{ departDate }} - {{ returnDate }}
                  </div>
                  <button
                    pButton
                    type="button"
                    label="Clear"
                    (click)="
                      clearDepartReturnDates(); departReturnDate.toggle($event)
                    "
                    class="cancelBtn btn btn-sm btn-default"
                  ></button>
                  <button
                    pButton
                    type="button"
                    label="Apply"
                    class="applyBtn btn btn-sm btn-primary"
                    (click)="applyDepartReturnDates()"
                  ></button>
                </div>
                <ng-container
                  *ngIf="
                    updateQuoteForm.get('rangeDates')?.invalid &&
                    (updateQuoteForm.get('rangeDates')?.dirty ||
                      updateQuoteForm.get('rangeDates')?.touched)
                  "
                >
                  <small
                    class="text-danger"
                    style="width: 190px; display: block"
                  >
                    <span
                      *ngIf="updateQuoteForm.get('rangeDates')?.errors?.['required']"
                    >
                      Please select the Depart & Return Date
                    </span>
                  </small>
                </ng-container>
                <!-- <small class="text-danger" style="width: 190px; display: block">
                  <span
                    *ngIf="updateQuoteForm.errors?.['returnDateMoreThan60Days']"
                  >
                    {{updateQuoteForm.errors?.['returnDateMoreThan60Days']}}
                  </span>
                </small> -->
                <small class="text-danger" style="width: 190px; display: block">
                  <span *ngIf="updateQuoteForm.errors?.['datesEqual']">
                    {{updateQuoteForm.errors?.['datesEqual']}}
                  </span>
                </small>
                <small class="text-danger" style="width: 190px; display: block">
                  <span
                    *ngIf="updateQuoteForm.errors?.['returnGreaterThanOneYear']"
                  >
                    {{updateQuoteForm.errors?.['returnGreaterThanOneYear']}}
                  </span>
                </small>
                <small class="text-danger" style="width: 190px; display: block">
                  <span *ngIf="updateQuoteForm.errors?.['returnDateRequired']">
                    {{updateQuoteForm.errors?.['returnDateRequired']}}
                  </span>
                </small>
              </p-overlayPanel>
              <div class="d-flex">
                <button
                  #departReturnDateBtn
                  type="button"
                  class="text-white border-0 bg-transparent px-0 py-2 pt-0"
                  (click)="departReturnDate.toggle($event)"
                >
                  <b data-bs-original-title
                    >{{ displayDepartDate }} - {{ displayReturnDate }}</b
                  >
                  <span class="ms-1"
                    ><i class="fa-solid fa-angle-down"></i
                  ></span>
                </button>
              </div>
            </div>
          </div>
          <!-- Adults -->
          <div class="col travellernumber">
            <div class="text-white">
              <span class="d-block"><small>Adults</small></span>
              <p-overlayPanel #travellers>
                <div class="row mb-3" style="width: 350px">
                  <label class="d-block font-20 mb-2"
                    ><strong>Adults</strong></label
                  >
                  <div class="row align-items-center m-auto">
                    <div class="col-6 col-sm-4 col-md-4 col-xl px-0">
                      <label for="" class="form-label">Adult 1 Age *</label>
                      <input
                        type="tel"
                        class="form-control"
                        placeholder="Adult 1 Age*"
                        aria-label=""
                        formControlName="age1"
                        maxLength="3"
                        pKeyFilter="int"
                      />
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-xl pe-0">
                      <label for="" class="form-label">Adult 2 Age </label>
                      <input
                        type="tel"
                        class="form-control"
                        placeholder="Adult 2 Age"
                        aria-label=""
                        (blur)="validateAge('second_age', $event)"
                        formControlName="age2"
                        maxLength="3"
                        pKeyFilter="int"
                      />
                    </div>
                  </div>

                  <!-- Error Messages -->
                  <div class="row align-items-center m-auto">
                    <div class="col-6 col-sm-4 col-md-4 col-xl px-0">
                      <ng-container
                        *ngIf="
                          updateQuoteForm.get('age1')?.invalid &&
                          (updateQuoteForm.get('age1')?.dirty ||
                            updateQuoteForm.get('age1')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="updateQuoteForm.get('age1')?.errors?.['required']"
                          >
                            {{errorMessages['first_age']?.['required']}}
                          </span>
                          <span
                            *ngIf="updateQuoteForm.get('age1')?.errors?.['min']"
                          >
                            {{(quoteEnquiry.tripType === 'Single' ? errorMessages['first_age']?.['min'] : errorMessages['first_age_multi']?.['min'])}}
                          </span>
                          <span
                            *ngIf="updateQuoteForm.get('age1')?.errors?.['max']"
                          >
                            {{(quoteEnquiry.tripType === 'Single' ? errorMessages['first_age']?.['max'] : errorMessages['first_age_multi']?.['max'])}}
                          </span>
                        </small>
                      </ng-container>
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-xl pe-0">
                      <ng-container
                        *ngIf="
                          updateQuoteForm.get('age2')?.invalid &&
                          (updateQuoteForm.get('age2')?.dirty ||
                            updateQuoteForm.get('age2')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="updateQuoteForm.get('age2')?.errors?.['min']"
                          >
                            {{(quoteEnquiry.tripType === 'Single' ? errorMessages['second_age']?.['min'] : errorMessages['second_age_multi']?.['min'])}}
                          </span>
                          <span
                            *ngIf="updateQuoteForm.get('age2')?.errors?.['max']"
                          >
                            {{(quoteEnquiry.tripType === 'Single' ? errorMessages['second_age']?.['max'] : errorMessages['second_age_multi']?.['max'])}}
                          </span>
                        </small>
                      </ng-container>
                    </div>
                  </div>

                  <div class="row align-items-center m-auto mt-1">
                    <div class="col-6 col-sm-4 col-md-4 col-xl px-0"></div>
                    <div class="col-6 col-sm-4 col-md-4 col-xl pe-0">
                      <label for="" class="form-label">&nbsp; </label>
                      <button
                        class="btn btn-info w-100"
                        id="travelersApply"
                        (click)="applyTravellerChanges(travellers)"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </p-overlayPanel>
              <button
                #travellersBtn
                type="button"
                (click)="travellers.toggle($event)"
                class="text-white border-0 bg-transparent px-0 py-2 pt-0"
                data-bs-original-title
              >
                <b data-bs-original-title>{{ getTravellersNum() }}</b>
                <span class="ms-1"
                  ><i class="fa-solid fa-angle-down" data-bs-original-title></i
                ></span>
              </button>
            </div>
            <div id="travellers" style="display: none">
              <div>
                <div class="row mb-3">
                  <label class="d-block font-20 mb-2"
                    ><strong>Travellers</strong></label
                  >

                  <div class="col-6 mb-2">
                    <label for="" class="form-label">Adult 1 Age *</label>
                    <input
                      type="text"
                      class="form-control"
                      name="Travellers"
                      autocomplete="off"
                      value=""
                    />
                  </div>

                  <div class="col-6 mb-2">
                    <label for="" class="form-label">Adult 2 Age </label>
                    <input
                      type="text"
                      class="form-control"
                      name="Travellers"
                      autocomplete="off"
                      value=""
                    />
                  </div>
                  <div class="col-6 mb-2">
                    <label for="" class="form-label">No. of Dependents </label>
                    <div class="input-group">
                      <button class="number-add-minus bg-transparent">
                        <span class="glyphicon glyphicon-plus">-</span>
                      </button>
                      <input
                        type="text"
                        class="form-control"
                        name="numberofdependent"
                        aria-label=""
                        style="
                          border-right: 0;
                          border-left: 0;
                          text-align: center;
                        "
                        value="0"
                      />
                      <button class="number-add-plus bg-transparent">
                        <span class="glyphicon glyphicon-plus">+</span>
                      </button>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="" class="form-label">&nbsp; </label>
                    <button class="btn btn-info w-100" id="travelersApply">
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Dependents -->
          <div class="col travellernumber">
            <div class="text-white">
              <span class="d-block"><small>Dependents</small></span>
              <p-overlayPanel #dependents>
                <div class="row mb-3" style="width: 350px">
                  <label class="d-block font-20 mb-2"
                    ><strong>Dependents</strong></label
                  >
                  <div class="row align-items-center m-auto">
                    <div class="col-6 col-sm-4 col-md-4 col-xl px-0">
                      <label for="" class="form-label"
                        >No. of Dependents
                      </label>
                      <div class="input-group">
                        <input
                          type="tel"
                          class="form-control"
                          placeholder="No. of Dependents"
                          aria-label=""
                          maxLength="2"
                          formControlName="dependentsCount"
                          pKeyFilter="int"
                        />
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-xl pe-0">
                      <label for="" class="form-label">&nbsp; </label>
                      <button
                        class="btn btn-info w-100"
                        id="travelersApply"
                        (click)="applyDependentChanges(dependents)"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </p-overlayPanel>
              <button
                #dependentsBtn
                type="button"
                (click)="dependents.toggle($event)"
                class="text-white border-0 bg-transparent px-0 py-2 pt-0"
                data-bs-original-title
              >
                <b data-bs-original-title>{{ getDependentsNum() }}</b>
                <span class="ms-1"
                  ><i class="fa-solid fa-angle-down" data-bs-original-title></i
                ></span>
              </button>
            </div>
          </div>
          <!-- RACV Member -->
          <div
            class="col clubmember"
            *ngIf="activatedClub.toUpperCase() !== 'W2C'"
          >
            <div class="text-white">
              <span class="d-block"
                ><small class="font-12"
                  >Are you an {{ activatedClub }} Member?</small
                ></span
              >
              <p-overlayPanel #member styleClass="overlay-custom-trip-type">
                <div class="mb-2">
                  <label class="d-block font-20 mb-2"
                    ><strong>{{ activatedClub }} Member?</strong></label
                  >
                  <p-radioButton
                    name="Member"
                    class="btn-check"
                    value="true"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="selectedMemberValue"
                    inputId="member_yes"
                    (click)="onMemberClick($event, true); member.hide()"
                  ></p-radioButton>

                  <label
                    for="member_yes"
                    class="btn btn-outline-info px-3 me-1"
                    [ngClass]="{
                      'selected-label': selectedMemberLabel === true
                    }"
                  >
                    Yes
                  </label>

                  <p-radioButton
                    name="Member"
                    class="btn-check"
                    value="false"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="selectedMemberValue"
                    inputId="member_no"
                    (onClick)="onMemberClick($event, false); member.hide()"
                  ></p-radioButton>

                  <label
                    for="member_no"
                    class="btn btn-outline-info px-3"
                    [ngClass]="{
                      'selected-label': selectedMemberLabel === false
                    }"
                  >
                    No
                  </label>
                </div>
              </p-overlayPanel>
              <button
                type="button"
                (click)="member.toggle($event)"
                class="text-white border-0 bg-transparent px-0 py-2 pt-0"
              >
                <b data-bs-original-title>{{
                  selectedMemberLabel === true ? 'Yes' : 'No'
                }}</b>
                <span
                  ><i class="fa-solid fa-angle-down" data-bs-original-title></i
                ></span>
              </button>
            </div>
          </div>

          <!-- update button -->
          <div class="col updatetrip text-center">
            <div class="mt-2">
              <button
                type="button"
                id=""
                class="text-white btn btn-info border border-1 font-14 btn-lg"
                (click)="updateQuote()"
              >
                Update Trip Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>

  <!-- Mobile Stikcy Blue Bar  -->
  <section class="bg-info sticky-top" id="mobile-sticky-toggle-bar">
    <div class="container-fluid">
      <div class="row pt-4 pb-2 align-items-end">
        <div class="col depart-date">
          <div class="text-white">
            <span class="d-block"
              ><b>{{ getDestination() }}</b></span
            >
            <div class="d-flex">
              <span class="text-white font-12">
                {{ displayDepartDate }} - {{ displayReturnDate }} | Adults
                {{ getTravellersNum() }}, Dependents {{ getDependentsNum() }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-2 text-end">
          <div class="mobile-toggle-icon">
            <span class="m-toggle-icon" #toggleIcon
              ><i class="fa-solid fa-pencil"></i
            ></span>
          </div>
        </div>
      </div>
    </div>

    <div id="mobile-toggle-section">
      <form [formGroup]="updateQuoteForm">
        <div class="container-fluid">
          <nav class="quote-mobile-view pt-1">
            <ul>
              <li class="toggle-item">
                <div class="d-flex justify-content-between btn-toggle-selector">
                  <div class="text-gray header-title">
                    <span>Travel Destination</span>
                  </div>
                  <div class="text-end">
                    <button class="bg-transparent border-0 text-info py-0">
                      {{ getDestination() }}
                    </button>
                  </div>
                </div>
                <!-- <div class="toggle-item-content"> -->
                <!-- <label class="d-block font-20 mb-2"><strong>Travel Destination</strong></label> -->
                <!-- <div class="input-group mb-3">
                  <span class="input-group-text bg-transparent border-e-0"
                    ><i class="fa-solid fa-magnifying-glass"></i>
                  </span>
                  <select
                    class="form-select"
                    id="m-inputTravelDestination"
                    aria-label=""
                    style="width: 80%; height: 80px"
                    multiple
                  >
                    <option value="">Select</option>
                    <option value="1">South Africa</option>
                    <option value="2">Canada</option>
                    <option value="3">Hong Kong</option>
                    <option value="3">Japan</option>
                    <option value="3">Malaysia</option>
                  </select>
                  <div class="tags-container w-100"></div>
                </div> -->
                <div class="toggle-item-content">
                  <ng-select
                    name="selectedCountries"
                    [items]="filteredCountries"
                    bindLabel="countryName"
                    [multiple]="true"
                    [(ngModel)]="selectedCountries"
                    [clearOnBackspace]="true"
                    [clearSearchOnAdd]="true"
                    (change)="onModelChange($event)"
                    dropdownPosition="bottom"
                    maxSelectedItems="300"
                    [searchFn]="searchFn"
                    (search)="countrySearchFn($event)"
                    [isOpen]="searchTerms?.length >= 2 ? true : false"
                    [openOnEnter]="false"
                    [ngModelOptions]="{ standalone: true }"
                    [hideSelected]="true"
                    (focus)="ngSelectIsFocused = true"
                    (blur)="handlecountrySearcBlur($event)"
                    formControlName="travelDestination"
                    #ms
                  >
                    <ng-template ng-optgroup-tmp let-item="item">
                      <span style="vertical-align: middle">{{
                        item.name
                      }}</span>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="d-block d-lg-none p-2 mobile-error">
                  <div class="col-12">
                    <small class="text-danger">
                      <div
                        class="error-message"
                        *ngIf="updateQuoteForm.get('travelDestination')?.errors?.['cautionCountry']"
                      >
                        {{updateQuoteForm.get('travelDestination')?.errors?.['cautionCountry']}}
                      </div>
                    </small>
                    <small class="text-danger">
                      <div
                        class="error-message"
                        *ngIf="updateQuoteForm.get('travelDestination')?.errors?.['cannotTravelCountry']"
                      >
                        <a
                          href="https://www.smartraveller.gov.au/destinations"
                          target="_blank"
                          >One or more of your destinations has a Travel Alert,
                          please click here to view the Alert(s).</a
                        >
                        {{updateQuoteForm.get('travelDestination')?.errors?.['cannotTravelCountry']}}
                      </div>
                    </small>
                    <small class="text-danger">
                      <div
                        class="error-message"
                        *ngIf="updateQuoteForm.get('travelDestination')?.errors?.['cannotTravelDomesticInternational']"
                      >
                        {{updateQuoteForm.get('travelDestination')?.errors?.['cannotTravelDomesticInternational']}}
                      </div>
                    </small>
                  </div>
                </div>
                <!-- </div> -->
              </li>
              <li class="toggle-item">
                <div class="d-flex justify-content-between btn-toggle-selector">
                  <div class="text-gray header-title">
                    <span>Trip Type</span>
                  </div>
                  <div class="text-end">
                    <button class="bg-transparent border-0 text-info py-0">
                      {{ getMobileTripType() }}
                    </button>
                  </div>
                </div>
                <div class="toggle-item-content">
                  <div class="">
                    <input
                      type="radio"
                      class="btn-check"
                      name="m_trip_type"
                      id="m_trip_single"
                      autocomplete="off"
                      value="'Single'"
                      [checked]="getMobileTripType() === 'Single'"
                      (click)="onTripTypeClick($event, 'Single'); trip.hide()"
                    />
                    <label class="btn btn-outline-info px-3" for="m_trip_single"
                      >Single Trip</label
                    >

                    <input
                      type="radio"
                      class="btn-check"
                      name="m_trip_type"
                      id="m_trip_multi"
                      autocomplete="off"
                      value="'Multi'"
                      [checked]="getMobileTripType() === 'Multiple'"
                      (click)="onTripTypeClick($event, 'Multiple'); trip.hide()"
                    />
                    <label class="btn btn-outline-info px-3" for="m_trip_multi"
                      >Annual Multi-Trip</label
                    >
                    <div class="d-flex align-items-top py-3 text-gray">
                      <span class="pe-2"
                        ><i class="fa-solid fa-circle-info"></i
                      ></span>
                      <span>
                        <ng-container
                          *ngIf="
                            selectedMobileTripType === 'Multiple' ||
                              selectedTripLabel === 'Multiple';
                            else singleTripRemarks
                          "
                        >
                          Provides cover for an unlimited number of trips within
                          a 12 month period, 250 kms from home. Max trip
                          duration 30, 45, 60 days. Please provide date you
                          depart for your first trip.
                        </ng-container>
                        <ng-template #singleTripRemarks>
                          Provides cover for a single domestic or international
                          round trip.
                        </ng-template>
                      </span>
                    </div>
                  </div>
                  <!-- <div class="toggle-item-content" style="display: none;">
                <div class=""> 
                    <input type="radio" class="btn-check" name="m_trip_type" id="m_trip_single" autocomplete="off" value="single" checked="">
                    <label class="btn btn-outline-info px-3 " for="m_trip_single">Single Trip</label>
    
                    <input type="radio" class="btn-check" name="m_trip_type" id="m_trip_multi" autocomplete="off" value="Mulit">
                    <label class="btn btn-outline-info px-3  " for="m_trip_multi">Annual- Multi Trip</label>                                                                                              
                    <div class="d-flex align-items-top py-3 text-gray"> <span class="pe-2"><i class="fa-solid fa-circle-info"></i></span> <span id="m_trip_info">Provides cover for a single domestic or international round trip.</span></div>
                </div>
              </div> -->
                </div>
              </li>

              <li>
                <div
                  class="d-flex justify-content-between"
                  id="mDepartReturnDate"
                >
                  <div class="text-gray header-title">
                    <span (click)="departReturnDate.toggle($event)"
                      >Depart & Return Date</span
                    >
                  </div>
                  <div class="text-end">
                    <div class="d-flex">
                      <button
                        id="departReturnDate"
                        name="m-dates"
                        (click)="departReturnDate.toggle($event)"
                        class="border-0 bg-transparent font-12 text-info input-date"
                      >
                        {{ displayDepartDate }} - {{ displayReturnDate }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="toggle-item-content">
                  <div id="customDateRange">
                    <p-overlayPanel #departReturnDate>
                      <div *ngIf="selectedTripLabel === 'Single'">
                        <p-calendar
                          formControlName="rangeDates"
                          (onSelect)="rangeDateSelect($event)"
                          [inline]="true"
                          selectionMode="range"
                          [readonlyInput]="true"
                          [minDate]="currentDate"
                          [maxDate]="maxDepartDate"
                          dateFormat="dd M ''y"
                          showOtherMonths="false"
                        ></p-calendar>
                      </div>
                      <div *ngIf="selectedTripLabel === 'Multiple'">
                        <p-calendar
                          formControlName="rangeDates"
                          (onSelect)="rangeDateSelect($event)"
                          [inline]="true"
                          [readonlyInput]="true"
                          [minDate]="currentDate"
                          [maxDate]="maxDepartDate"
                          dateFormat="dd M ''y"
                          showOtherMonths="false"
                        ></p-calendar>
                      </div>
                      <div class="drp-buttons">
                        <div class="drp-selected">
                          {{ departDate }} - {{ returnDate }}
                        </div>
                        <button
                          pButton
                          type="button"
                          label="Clear"
                          (click)="
                            clearDepartReturnDates();
                            departReturnDate.toggle($event)
                          "
                          class="cancelBtn btn btn-sm btn-default"
                        ></button>
                        <button
                          pButton
                          type="button"
                          label="Apply"
                          class="applyBtn btn btn-sm btn-primary"
                          (click)="applyDepartReturnDates()"
                        ></button>
                      </div>
                      <ng-container
                        *ngIf="
                          updateQuoteForm.get('rangeDates')?.invalid &&
                          (updateQuoteForm.get('rangeDates')?.dirty ||
                            updateQuoteForm.get('rangeDates')?.touched)
                        "
                      >
                        <small
                          class="text-danger"
                          style="width: 190px; display: block"
                        >
                          <span
                            *ngIf="updateQuoteForm.get('rangeDates')?.errors?.['required']"
                          >
                            Please select the Depart & Return Date
                          </span>
                        </small>
                      </ng-container>
                      <!-- <small
                        class="text-danger"
                        style="width: 190px; display: block"
                      >
                        <span
                          *ngIf="updateQuoteForm.errors?.['returnDateMoreThan60Days']"
                        >
                          {{updateQuoteForm.errors?.['returnDateMoreThan60Days']}}
                        </span>
                      </small> -->
                      <small
                        class="text-danger"
                        style="width: 190px; display: block"
                      >
                        <span *ngIf="updateQuoteForm.errors?.['datesEqual']">
                          {{updateQuoteForm.errors?.['datesEqual']}}
                        </span>
                      </small>
                      <small
                        class="text-danger"
                        style="width: 190px; display: block"
                      >
                        <span
                          *ngIf="updateQuoteForm.errors?.['returnGreaterThanOneYear']"
                        >
                          {{updateQuoteForm.errors?.['returnGreaterThanOneYear']}}
                        </span>
                      </small>
                      <small
                        class="text-danger"
                        style="width: 190px; display: block"
                      >
                        <span
                          *ngIf="updateQuoteForm.errors?.['returnDateRequired']"
                        >
                          {{updateQuoteForm.errors?.['returnDateRequired']}}
                        </span>
                      </small>
                    </p-overlayPanel>
                  </div>
                </div>
              </li>
              <li class="toggle-item">
                <div class="d-flex justify-content-between btn-toggle-selector">
                  <div class="text-gray header-title">
                    <span>Adults</span>
                  </div>
                  <div class="text-end">
                    <button class="bg-transparent border-0 text-info py-0">
                      {{ getTravellersNum() }}
                    </button>
                  </div>
                </div>
                <div class="toggle-item-content">
                  <div>
                    <div class="mb-3 row">
                      <div class="col-6 mb-2">
                        <label for="" class="form-label">Adult 1 Age *</label>
                        <input
                          type="tel"
                          class="form-control"
                          name="Travellers"
                          autocomplete="off"
                          value=""
                          formControlName="age1"
                          maxLength="3"
                          pKeyFilter="int"
                        />
                      </div>

                      <div class="col-6 mb-2">
                        <label for="" class="form-label">Adult 2 Age </label>
                        <input
                          type="tel"
                          class="form-control"
                          name="Travellers"
                          autocomplete="off"
                          value=""
                          (blur)="validateAge('second_age', $event)"
                          formControlName="age2"
                          maxLength="3"
                          pKeyFilter="int"
                        />
                      </div>

                      <!-- Error Messages -->
                      <div class="row align-items-center m-auto">
                        <div class="col-6 col-sm-4 col-md-4 col-xl px-0">
                          <ng-container
                            *ngIf="
                              updateQuoteForm.get('age1')?.invalid &&
                              (updateQuoteForm.get('age1')?.dirty ||
                                updateQuoteForm.get('age1')?.touched)
                            "
                          >
                            <small class="text-danger">
                              <span
                                *ngIf="updateQuoteForm.get('age1')?.errors?.['required']"
                              >
                                {{errorMessages['first_age']?.['required']}}
                              </span>
                              <span
                                *ngIf="updateQuoteForm.get('age1')?.errors?.['min']"
                              >
                                {{(quoteEnquiry.tripType === 'Single' ? errorMessages['first_age']?.['min'] : errorMessages['first_age_multi']?.['min'])}}
                              </span>
                              <span
                                *ngIf="updateQuoteForm.get('age1')?.errors?.['max']"
                              >
                                {{(quoteEnquiry.tripType === 'Single' ? errorMessages['first_age']?.['max'] : errorMessages['first_age_multi']?.['max'])}}
                              </span>
                            </small>
                          </ng-container>
                        </div>
                        <div class="col-6 col-sm-4 col-md-4 col-xl pe-0">
                          <ng-container
                            *ngIf="
                              updateQuoteForm.get('age2')?.invalid &&
                              (updateQuoteForm.get('age2')?.dirty ||
                                updateQuoteForm.get('age2')?.touched)
                            "
                          >
                            <small class="text-danger">
                              <span
                                *ngIf="updateQuoteForm.get('age2')?.errors?.['min']"
                              >
                                {{(quoteEnquiry.tripType === 'Single' ? errorMessages['second_age']?.['min'] : errorMessages['second_age_multi']?.['min'])}}
                              </span>
                              <span
                                *ngIf="updateQuoteForm.get('age2')?.errors?.['max']"
                              >
                                {{(quoteEnquiry.tripType === 'Single' ? errorMessages['second_age']?.['max'] : errorMessages['second_age_multi']?.['max'])}}
                              </span>
                            </small>
                          </ng-container>
                        </div>
                      </div>

                      <div class="col-6 mb-2"></div>
                      <div class="col-6">
                        <label for="" class="form-label">&nbsp; </label>
                        <button
                          class="btn btn-info w-100"
                          id="travelersApply"
                          (click)="applyTravellerChanges(null)"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="toggle-item">
                <div class="d-flex justify-content-between btn-toggle-selector">
                  <div class="text-gray header-title">
                    <span>Dependents</span>
                  </div>
                  <div class="text-end">
                    <button class="bg-transparent border-0 text-info py-0">
                      {{ getDependentsNum() }}
                    </button>
                  </div>
                </div>
                <div class="toggle-item-content">
                  <div>
                    <div class="mb-3 row">
                      <div class="col-6 mb-2">
                        <label for="" class="form-label"
                          >No. of Dependents
                        </label>
                        <div class="input-group">
                          <input
                            type="tel"
                            class="form-control"
                            formControlName="dependentsCount"
                            aria-label=""
                            maxLength="2"
                            pKeyFilter="int"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <label for="" class="form-label">&nbsp; </label>
                        <button
                          class="btn btn-info w-100"
                          id="travelersApply"
                          (click)="applyDependentChanges(null)"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="toggle-item">
                <div class="d-flex justify-content-between btn-toggle-selector">
                  <div class="text-gray header-title">
                    <span>Are you an {{ activatedClub }} Member?</span>
                  </div>
                  <div class="text-end">
                    <button class="bg-transparent border-0 text-info py-0">
                      {{ selectedMemberLabel === true ? 'Yes' : 'No' }}
                    </button>
                  </div>
                </div>
                <div class="toggle-item-content">
                  <div class="">
                    <label class="d-block font-20 mb-2"
                      ><strong>{{ activatedClub }} Member?</strong></label
                    >
                    <p-radioButton
                      name="Member"
                      class="btn-check"
                      value="true"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="selectedMemberValue"
                      inputId="member_yes"
                      (click)="onMemberClick($event, true)"
                    ></p-radioButton>

                    <label
                      for="member_yes"
                      class="btn btn-outline-info px-3 me-1"
                      [ngClass]="{
                        'selected-label': selectedMemberLabel === true
                      }"
                    >
                      Yes
                    </label>

                    <p-radioButton
                      name="Member"
                      class="btn-check"
                      value="false"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="selectedMemberValue"
                      inputId="member_no"
                      (onClick)="onMemberClick($event, false)"
                    ></p-radioButton>

                    <label
                      for="member_no"
                      class="btn btn-outline-info px-3"
                      [ngClass]="{
                        'selected-label': selectedMemberLabel === false
                      }"
                    >
                      No
                    </label>
                  </div>
                </div>
              </li>
              <li>
                <div class="my-3">
                  <button
                    type="buton"
                    class="btn btn-info btn-lg w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#updatequote"
                  >
                    Update
                  </button>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </form>
    </div>
  </section>

  <!-- Step Bar -->
  <app-step-bar
    [customStyles]="{ width: '0%' }"
    [activeStep]="'step1'"
  ></app-step-bar>

  <div id="chooseoptions"></div>
  <!-- single trip -->
  <section class="quote selection">
    <div class="container" *ngIf="tabs.length > 0">
      <div class="row mt-2 mt-md-5">
        <div class="col-md-12 ps-1 pe-1">
          <!-- primeNG -->
          <div
            *ngIf="quoteEnquiry?.isSingleTrip"
            class="main-mostpopular"
            [class.mostpopular-left]="alignment === 'left'"
            [class.mostpopular-center]="alignment === 'center'"
            [class.mostpopular-right]="alignment === 'right'"
            [class.mostpopular-three]="tabs.length === 3"
            [class.mostpopular-two]="tabs.length === 2"
            [class.mostpopular-one]="tabs.length === 1"
          >
            <div class="mostpopular pt-1">Now With COVID-19 Benefits</div>
          </div>
          <p-tabView
            styleClass="tabview-custom"
            [ngClass]="{ 'amt-tabview': !quoteEnquiry?.isSingleTrip }"
            (onChange)="onTabChange($event)"
            [(activeIndex)]="selectedTabIndex"
          >
            <p-tabPanel *ngFor="let tab of tabs" [header]="tab?.title">
              <ng-template pTemplate="header">
                <div
                  *ngIf="quoteEnquiry?.isSingleTrip && !isDomestic"
                  class="row align-items-end text-start text-custom topcover"
                >
                  <div class="col-12 ps-0 ifdiscountedprice">
                    <h4 class="text-left mb-4 tab-title">
                      {{ tab?.price?.selectedCoverTitle }}
                    </h4>
                  </div>
                  <div class="col-sm-6 col-12 ps-2">
                    <div class="row ifdiscountedprice">
                      <div class="col-12 text-dark ps-0">
                        <p class="text-left mb-0">{{ standardPriceLabel }}</p>
                        <h4
                          *ngIf="showDiscountedPrice"
                          class="text-left"
                          ngClass="{{
                            showDiscountedPrice
                              ? 'text-decoration-line-through'
                              : ''
                          }}"
                        >
                          ${{
                            tab?.price?.selectedPrice?.regularPremium
                              | number : '1.2-2'
                          }}
                        </h4>
                        <h4
                          *ngIf="!showDiscountedPrice"
                          class="text-left"
                          ngClass="{{
                            showDiscountedPrice
                              ? 'text-decoration-line-through'
                              : ''
                          }}"
                        >
                          ${{
                            tab?.price?.selectedPrice?.totalPremium
                              | number : '1.2-2'
                          }}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-sm-6 col-12 p-0 text-dark"
                    *ngIf="showDiscountedPrice"
                  >
                    <p class="mb-0">{{ discountedPriceLabel }}</p>
                    <h4>
                      ${{
                        tab?.price?.selectedPrice?.totalPremium
                          | number : '1.2-2'
                      }}
                    </h4>
                  </div>
                </div>
                <div
                  *ngIf="
                    quoteEnquiry?.isSingleTrip &&
                    isDomestic &&
                    activatedClub === 'W2C'
                  "
                  class="rounded-top-20 bg-multi-header col-12"
                >
                  <div class="row p-3">
                    <div class="col-lg-7 mb-3">
                      <div class="row">
                        <div class="col-5">
                          <h4 class="text-left mb-4 text-info">
                            {{ tab?.price?.selectedCoverTitle }}
                          </h4>
                        </div>
                        <div class="col-7 text-dark d-flex">
                          <div class="me-4">
                            <p class="text-left mb-1">
                              {{ standardPriceLabel }}
                            </p>
                            <h4
                              *ngIf="showDiscountedPrice"
                              class="text-left"
                              ngClass="{{
                                showDiscountedPrice
                                  ? 'text-decoration-line-through'
                                  : ''
                              }}"
                            >
                              <strong>
                                ${{
                                  tab?.price?.selectedPrice?.regularPremium
                                    | number : '1.2-2'
                                }}
                              </strong>
                            </h4>
                            <h4
                              *ngIf="!showDiscountedPrice"
                              class="text-left"
                              ngClass="{{
                                showDiscountedPrice
                                  ? 'text-decoration-line-through'
                                  : ''
                              }}"
                            >
                              <strong
                                >${{
                                  tab?.price?.selectedPrice?.totalPremium
                                }}</strong
                              >
                            </h4>
                          </div>
                          <div *ngIf="showDiscountedPrice">
                            <p class="mb-1">{{ discountedPriceLabel }}</p>
                            <h4>
                              <strong
                                >${{
                                  tab?.price?.selectedPrice?.totalPremium
                                }}</strong
                              >
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="
                    quoteEnquiry?.isSingleTrip &&
                    isDomestic &&
                    activatedClub !== 'W2C'
                  "
                  class="row align-items-end text-start text-custom topcover"
                >
                  <div class="col-12 ps-0 ifdiscountedprice">
                    <h4 class="text-left mb-4 tab-title">
                      {{ tab?.price?.selectedCoverTitle }}
                    </h4>
                  </div>
                  <div class="col-sm-6 col-12 ps-2">
                    <div class="row ifdiscountedprice">
                      <div class="col-12 text-dark ps-0">
                        <p class="text-left mb-0">Standard Price</p>
                        <h4
                          *ngIf="showDiscountedPrice"
                          class="text-left"
                          ngClass="{{
                            showDiscountedPrice
                              ? 'text-decoration-line-through'
                              : ''
                          }}"
                        >
                          ${{
                            tab?.price?.selectedPrice?.regularPremium
                              | number : '1.2-2'
                          }}
                        </h4>
                        <h4
                          *ngIf="!showDiscountedPrice"
                          class="text-left"
                          ngClass="{{
                            showDiscountedPrice
                              ? 'text-decoration-line-through'
                              : ''
                          }}"
                        >
                          ${{
                            tab?.price?.selectedPrice?.totalPremium
                              | number : '1.2-2'
                          }}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-sm-6 col-12 p-0 text-dark"
                    *ngIf="showDiscountedPrice"
                  >
                    <p class="mb-0">Member Price</p>
                    <h4>
                      ${{
                        tab?.price?.selectedPrice?.totalPremium
                          | number : '1.2-2'
                      }}
                    </h4>
                  </div>
                </div>
                <div
                  class="rounded-top-20 bg-multi-header col-12"
                  *ngIf="!quoteEnquiry?.isSingleTrip"
                >
                  <div class="row p-3">
                    <div class="col-lg-7 mb-3">
                      <div class="row">
                        <div class="col-5">
                          <h4 class="text-left mb-4 text-info">
                            {{ tab?.price?.selectedCoverTitle }}
                          </h4>
                        </div>
                        <div class="col-7 text-dark d-flex">
                          <div class="me-4">
                            <p class="text-left mb-1">
                              {{ standardPriceLabel }}
                            </p>
                            <h4
                              *ngIf="showDiscountedPrice"
                              class="text-left"
                              ngClass="{{
                                showDiscountedPrice
                                  ? 'text-decoration-line-through'
                                  : ''
                              }}"
                            >
                              <strong>
                                ${{
                                  tab?.price?.selectedPrice?.regularPremium
                                    | number : '1.2-2'
                                }}
                              </strong>
                            </h4>
                            <h4
                              *ngIf="!showDiscountedPrice"
                              class="text-left"
                              ngClass="{{
                                showDiscountedPrice
                                  ? 'text-decoration-line-through'
                                  : ''
                              }}"
                            >
                              <strong
                                >${{
                                  tab?.price?.selectedPrice?.totalPremium
                                    | number : '1.2-2'
                                }}</strong
                              >
                            </h4>
                          </div>
                          <div *ngIf="showDiscountedPrice">
                            <p class="mb-1">{{ discountedPriceLabel }}</p>
                            <h4>
                              <strong
                                >${{
                                  tab?.price?.selectedPrice?.totalPremium
                                    | number : '1.2-2'
                                }}</strong
                              >
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <div class="">
                        <label class="col-form-label d-block font-20 mb-2 pt-0"
                          >Choose Your Max Trip Length</label
                        >

                        <button
                          *ngFor="
                            let multiTripLength of selectedCover.multiTripLengthValues
                          "
                          type="button"
                          class="btn btn-outline-info px-4 mb-1 eqwidth"
                          [ngClass]="{
                            'selected-label':
                              selectedTripLenghtLabel === multiTripLength
                          }"
                          pButton
                          (click)="onTripLenghtClick($event, multiTripLength)"
                        >
                          {{ multiTripLength }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="content">
                <div
                  class="col-12 benifitssection align-content-center text-center"
                >
                  <p
                    class="covidalert p-3 text-center d-block fw-semibold"
                    *ngIf="
                      tab?.type === 'Tier3' &&
                      !isDomestic &&
                      activatedClub === 'W2C'
                    "
                  >
                    <img
                      src="assets/images/raa/coronavirus.png"
                      class="text-center pe-2"
                    />Top Cover now with COVID-19 Benefits
                  </p>

                  <div class="row benifitssections mt-3">
                    <div
                      class="col-4 col-lg p-0 m-0"
                      *ngFor="let topBenefit of allTopSixBenefits"
                    >
                      <div class="insider mt-3 mb-4">
                        <img
                          [src]="
                            topBenefit[currentTier] !== 'Not Applicable'
                              ? topBenefit.greenIcon
                              : topBenefit.redIcon
                          "
                          class="mb-2"
                        />
                        <h4 class="mb-1">
                          {{ topBenefit.benefits
                          }}<sup
                            *ngIf="
                              topBenefit.benefits === 'COVID-19' &&
                              topBenefit.isHash &&
                              topBenefit[currentTier] !== 'Not Applicable'
                            "
                            >#</sup
                          >
                          <sup
                            *ngIf="
                              topBenefit.benefits !== 'COVID-19' &&
                              topBenefit.isHash
                            "
                            >#</sup
                          >
                        </h4>
                        <span class="text-primary fw-semibold">{{
                          topBenefit[currentTier]
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <app-choose-your-option
                    *ngIf="
                      selectedCover?.selectedPrice?.productType !==
                      'Cancellation'
                    "
                    [quoteEnquiry]="quoteEnquiry"
                    [selectedCover]="selectedCover"
                    [isCruise]="isCruiseCountry"
                    [isSki]="isSki"
                    (optionChange)="onOptionChange($event)"
                  ></app-choose-your-option>
                </div>
              </ng-template>
            </p-tabPanel>
          </p-tabView>
          <!-- Compare All Benefits Table Start -->
          <div class="p-md-5 pb-4 compare-benefits benefits-tbl-border">
            <div class="">
              <table
                *ngIf="!isMobile"
                class="table compare text-left"
                [ngClass]="{ 'amt-tabviews': !quoteEnquiry?.isSingleTrip }"
              >
                <thead>
                  <tr
                    class="sticky-top dynamic-top"
                    style="top: 88px; z-index: 99"
                  >
                    <th class="bg-info-light firstth">Compare all Benefits</th>
                    <th
                      class="bg-info-light"
                      *ngFor="let tab of tabs"
                      [ngStyle]="
                        tabs.length === 3
                          ? { width: '21.66%' }
                          : { width: 'auto' }
                      "
                    >
                      {{ tab?.price?.selectedCoverTitle }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let product of allBenefits; let i = index"
                  >
                    <tr
                      class="table-row collapsed"
                      [attr.aria-expanded]="false"
                      [attr.aria-controls]="'row_' + i"
                      (click)="toggleCollapse($event, i)"
                    >
                      <td>
                        <strong>{{ product.benefits }}</strong>
                      </td>
                      <td *ngFor="let tab of tabs">
                        {{ product[tab?.price?.selectedCoverTitle] }}
                      </td>
                    </tr>
                    <tr id="row_{{ i }}" class="accordion-collapse collapse">
                      <td colspan="4">
                        <div class="collapse-content">
                          <div class="p-2">{{ product.details }}</div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>

              <!-- Accordion in Mobile -->
              <div *ngIf="isMobile" class="accordion mb-5" id="compare-table">
                <div class="p-3 compare-accordion-header">
                  Compare All Benefits
                </div>
                <div
                  class="accordion-item"
                  *ngFor="let product of allBenefits; let i = index"
                >
                  <span
                    class="accordion-button collapsed"
                    type="button"
                    [attr.aria-expanded]="false"
                    [attr.aria-controls]="'mrow_' + i"
                    (click)="toggleCollapseMobile($event, i)"
                  >
                    <strong>{{ product.benefits }}</strong>
                  </span>
                  <div id="mrow_{{ i }}" class="accordion-collapse collapse">
                    <div class="accordion-body">
                      <div
                        *ngFor="let tab of tabs"
                        class="d-flex justify-content-between"
                      >
                        <div class="col-5">
                          <b>{{ tab?.price?.selectedCoverTitle }}</b>
                        </div>
                        <div class="col-7">
                          {{ product[tab?.price?.selectedCoverTitle] }}
                        </div>
                      </div>
                      <div class="py-2">{{ product.details }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 conditionsapply">
              <div class="p-3 mb-3 graybg">
                <p class="text-left mb-1">#Limits and sub-limits apply.</p>
                <p class="text-left mb-1">
                  ^^ $unlimited means that generally there is no cap on the
                  maximum dollar amount which may be paid out for this benefit,
                  subject to the specific terms and conditions, sub-limits and
                  exclusions that apply to this benefit.
                </p>
                <p class="text-left mb-1">
                  ** $unlimited means that generally there is no cap on the
                  maximum dollar amount which may be paid out for this benefit,
                  subject to the specific terms and conditions, sub-limits and
                  exclusions that apply to this benefit. This benefit covers
                  reasonable overseas medical and hospital costs as a result of
                  an injury (including that arising from a Terrorist Act) or
                  illness occurring which first shows itself during Your Period
                  of Insurance. Benefits may be paid up to 12 months from the
                  time You received treatment for the injury or illness, but
                  only for <strong>reasonable expenses</strong> incurred during
                  that time. Medical treatments must be provided by a legally
                  qualified medical practitioner. You must notify Us as soon as
                  practicable of Your admittance to hospital.
                </p>
                <p class="text-left mb-1">
                  *This cover is per person listed in Your Policy
                </p>
                <p class="text-left mb-1">^This cover is per policy</p>
              </div>

              <a
                class="button btn btn-outline-info p-1 px-2 save-quote"
                (click)="
                  openSaveAndSendModal(); triggerSegmentEvent('saveEmailMain')
                "
                ><span>Save & Email Quote</span>
                <img src="assets/icons/raa/envelope.svg" alt="" width="24" />
              </a>
              <a
                (click)="downloadPDS(); triggerSegmentEvent('downloadPdsMain')"
                class="button btn btn-outline-info p-1 px-2 download-quote"
                ><span id="downloadPDSButton">Download PDS</span>
                <img
                  src="assets/images/raa/downloadracv.png"
                  alt=""
                  width="24"
                />
              </a>
              <a
                class="button btn btn-outline-info ms-2 me-3"
                (click)="
                  openSaveAndSendModal();
                  triggerSegmentEvent('saveEmailSideIcon')
                "
                >Save & Email Quote</a
              >
              <a
                (click)="
                  downloadPDS(); triggerSegmentEvent('downloadPdslSideIcon')
                "
                class="button btn btn-outline-info"
                >Download PDS</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-email-quote #saveAndSendEmail></app-email-quote>

  <app-racv-footer></app-racv-footer>

  <!-- Sticky footer -->
  <div
    class="container-fluid pt-1 pt-md-3 pb-3 fixed-bottom only-mobile-none"
    id="bottom-bar"
  >
    <div class="row justify-content-center">
      <div class="col-xl-10 col-md-12 col-12">
        <div class="row">
          <div class="col-sm-2 col-6 order-2 order-sm-1 text-m-center">
            <label class="coverselections">Selected plan</label>
            <h2>{{ selectedCover?.selectedCoverTitle }}</h2>
          </div>
          <div class="col-sm-2 col-6 order-3 order-sm-2 text-m-center">
            <label class="coverselections">Total Cost</label>
            <h2>
              ${{
                selectedCover?.selectedPrice?.totalPremium | number : '1.2-2'
              }}
            </h2>
          </div>
          <div class="col-sm-3 col-12 text-center order-1 order-sm-3">
            <div class="d-none d-md-block">
              <a
                href="#"
                [attr.data-bs-toggle]="
                  hasQuoteFormValueChange ? 'modal' : 'offcanvas'
                "
                [attr.data-bs-target]="
                  hasQuoteFormValueChange ? '#updatequote' : '#offcanvasBottom'
                "
                ><img src="assets/images/raa/uparrow_raa.png" class=""
              /></a>
              <p
                [attr.data-bs-toggle]="
                  hasQuoteFormValueChange ? 'modal' : 'offcanvas'
                "
                [attr.data-bs-target]="
                  hasQuoteFormValueChange ? '#updatequote' : '#offcanvasBottom'
                "
              >
                View full price breakdown
              </p>
            </div>
            <div class="d-block d-md-none">
              <a
                href="#"
                [attr.data-bs-toggle]="
                  hasQuoteFormValueChange ? 'modal' : 'offcanvas'
                "
                [attr.data-bs-target]="
                  hasQuoteFormValueChange ? '#updatequote' : '#offcanvasBottom'
                "
                class="text-decoration-none"
                ><img
                  src="assets/images/raa/uparrow_raa.png"
                  style="width: 18px"
                />&nbsp;View full price breakdown</a
              >
            </div>
          </div>
          <div
            class="col-sm-5 col-12 order-4 order-sm-4 text-lg-right text-m-center"
          >
            <div class="d-none d-md-block">
              <a
                onClick="document.getElementById('chooseoptions').scrollIntoView()"
                class="btn btn-outline-info btn-lg mt-1 addoption stickyftbutton"
                >Add Options</a
              >
              <a
                #proceedBtn
                class="btn btn-info btn-lg ps-4 pe-4 mt-1 stickyproceed stickyftbutton"
                data-bs-toggle="modal"
                [attr.data-bs-target]="
                  hasQuoteFormValueChange ? '#updatequote' : '#staticBackdrop'
                "
                (click)="this.sendProceedEvent(); triggerSegmentQuoteDetails()"
                >Proceed</a
              >
            </div>
            <div class="d-block d-md-none">
              <a
                onClick="document.getElementById('chooseoptions').scrollIntoView()"
                class="btn btn-outline-info btn-sm addoption stickyftbutton"
                >Add Options</a
              >
              <a
                #proceedBtn
                class="btn btn-info btn-sm ps-4 pe-4 stickyproceed stickyftbutton"
                data-bs-toggle="modal"
                [attr.data-bs-target]="
                  hasQuoteFormValueChange ? '#updatequote' : '#staticBackdrop'
                "
                (click)="this.sendProceedEvent()"
                >Proceed</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- sticky footer popup -->
  <app-footer-price-breakdown
    [selectedCover]="selectedCover"
    [quoteEnquiry]="quoteEnquiry"
    [quoteDetail]="quoteDetail"
    (onSubmitBtnClick)="footerPopupclick()"
    [step]="'step1'"
  ></app-footer-price-breakdown>

  <!-- Modal COVID-->
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Covid-19 Information
          </h5>
        </div> -->
        <div class="modal-body">
          <p>
            As a standard exclusion under all policies, the country to which you
            are travelling to must not be subject to a Do Not Travel advice on
            the Smartraveller website at the time you purchased the policy. This
            is regardless of any exemption you may have received to travel and
            your ability to purchase a policy.
          </p>
          <p class="fw-bold">
            For more information on all active Travel Alerts, as well as
            information specifically relating to policies purchased and trips
            booked prior to today, please refer to the PDS.
          </p>
          <p class="fw-bold">
            <ng-container *ngIf="hasCovid19Coverage(); else noCovid19Cover">
              You have selected a product that includes limited cover for
              COVID-19.
            </ng-container>
            <ng-template #noCovid19Cover>
              You have selected a product that DOES NOT include cover for
              COVID-19.
            </ng-template>
          </p>
          <p>
            We provide certain policies which include limited benefits for
            COVID-19. For all other policies we provide, COVID-19 related losses
            are part of a General Exclusion and not intended to be covered.
            Please refer to the Product Disclosure Statement for the policy
            terms, limits conditions and exclusions relevant to this cover. If
            you have purchased an international or domestic policy from us that
            does not include COVID-19 benefits, this notice informs you of the
            knowledge that there would be no intended coverage for any claim
            that relates to COVID-19. This applies to any section of the policy.
          </p>
        </div>
        <div class="modal-footer">
          <a type="button" class="btn btn-outline-info" data-bs-dismiss="modal"
            >Cancel</a
          >
          <button
            class="btn btn-info"
            data-bs-dismiss="modal"
            [disabled]="isButtonDisabled"
            (click)="submitTheQuoteDetail()"
          >
            Accept and Continue
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal UPDATE-->
  <div
    class="modal fade"
    id="updatequote"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Update Alert!</h5>
        </div>
        <div class="modal-body">
          <p>
            Your trip details have been modified. Click the 'Update Quote'
            button to view the revised Quote. If you prefer to keep the current
            Quote, click 'Cancel'.
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-info"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-info"
            data-bs-dismiss="modal"
            (click)="updateQuote()"
          >
            Update Quote
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Expired Quote -->
  <div
    class="modal"
    [ngClass]="{ show: quoteExpired || resumeResponse?.policyStatus == 'N' }"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <span *ngIf="quoteExpired"
            >As quotes are only valid for 14 days this quote is now expired.
            Please create a new quote to
            <a href="javascript:void(0);" (click)="goToStepOne()">continue.</a>
          </span>
          <span *ngIf="resumeResponse?.policyStatus == 'N'">
            This quote is no longer valid due to a PDS or product change. Please
            arrange a new quote to
            <a href="javascript:void(0);" (click)="goToStepOne()">continue.</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
